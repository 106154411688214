export default {
  'Vai alla sezione': 'Read More',
  'Mettiti in contatto con noi': 'Get in touch with us',
  'Questo sito non utilizza cookie di profilazione. Si fa invece ricorso a cookie tecnici, anche di terze parti, al fine di migliorare l\'esperienza di navigazione. Cliccando sul tasto “Rifiuta”, chiuderai il banner senza prestare il consenso ad alcun cookie, ad eccezione di quelli necessari al corretto funzionamento del sito.': 'This site does not use profiling cookies. Instead, technical cookies, including third-party cookies, are used in order to improve the browsing experience. By clicking on the "Reject" button, you will close the banner without giving consent to any cookies, except those necessary for the correct functioning of the site.',
  'Accetta ': 'Accept',
  'Rifiuta ': 'Decline',
  'Impostazioni cookie': 'Cookie Settings',
  'Impostazioni ': 'Settings',
  'Cookie strettamente necessari': 'Strictly necessary cookies',
  'I cookie strettamente necessari sono cookie tecnici, che non raccolgono dati, necessari per la corretta visualizzazione del sito web.': 'Strictly necessary cookies are technical cookies, which do not collect data, necessary for the correct display of the website.',
  'Cookie di terze parti': 'Third party cookies',
  'Google Maps è un servizio di visualizzazione di mappe gestito da Google che aiuta i visitatori nella localizzazione dell\'azienda. Questo servizio è fornito utilizzando esclusivamente cookie tecnici.': 'Google Maps is a map viewing service managed by Google that helps visitors locate your company. This service is provided using exclusively technical cookies.',
  'Salva le modifiche': 'Save settings',
  'Attivo ': 'Active',
  'Approfondisci ': 'Read More',
  'Per presa visione dell\'informativa privacy ai sensi del RE 2016/679': 'I acknowledge that I have read and accept the privacy policy and consent to the processing of my personal data.',
  'Chiarezza.': 'Clarity.',
  'Trasparenza.': 'Transparency.',
  'Semplicità.': 'Simplicity.',
  'Valori Sostenibili': 'Sustainable Values',
  'Campi di applicazione': 'Application fields',
  'Ospedaliero ': 'Hospital',
  'Tessuti Umani': 'Human Tissues',
  'Veterinario ': 'Veterinary',
  'Farmaceutico ': 'Pharmaceutical',
  'Aerospaziale ': 'Aerospace',
  'Cosmetico ': 'Cosmetic',
  'Medico ': 'Medical Device',
  'Impianti Dentali': 'Dental Implants',
  'I campi di applicazione': 'Application Fields',
  'Certificazioni ': 'Certifications',
  'La nostra storia.': 'Our History.',
  'Evidenze ': 'Evidence',
  'Partnership di Eccellenza': 'Partnerships of Excellence',
  'Gammatom ha sviluppato collaborazioni e relazioni con enti rilevanti a livello nazionale e internazionale, promuovendo la ricerca e lo sviluppo in ambito universitario e sanitario.': 'Gammatom has developed collaborations and relationships with significant national and international entities, promoting research and development in the academic and healthcare sectors.',
  'Visualizza tabella': 'View the table',
  'Visualizza ': 'View more',
  'Guarda il video': 'View the video',
  'Contatti telefonici': 'Phone contacts',
  'Contatto e-mail': 'E-mail contact',
  'Sede legale': 'Headquarters',
  'Orari di apertura': 'Opening hours',
  'Contattaci ': 'Contact us',
  'Centralino ': 'Contact Center',
  'Come raggiungerci': 'Find us'
}
