<template>
  <div class="page">
      <!-- HEADING 1 IMAGE BIG -->

      <plcd-page-heading
        :title="page.title"
        :subtitle="page.plcd_page_subtitle"
        :svgbg="true"
      >
      </plcd-page-heading>

      <section class="page-content" id="contentSection">
        <bxs-layout>
          <bxs-row>
            <bxs-col
            cols="12"
            ds="6"
            class="bxs-col-no-padding"
            >
            <div class="page-content__text page-content__text--inverted" v-html="page && page.plcd_page_content">
            </div>
            </bxs-col>
            <bxs-col
            cols="12"
            ds="6"
            >
              <div
              ref="stickyBlock"
              class="stickyBlock"
              data-scroll
              data-scroll-sticky
              data-scroll-repeat="true"
              data-scroll-target="#contentSection"
              >
                <bxs-figure :src="page.img" />
                <h2 class="stickyBlock__title animation-ready medium">{{ page.title }}</h2>
              </div>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>
      <section class="tecnology-block">
        <bxs-layout>
          <bxs-row
          v-if="activeLang == 'it'">
            <bxs-col
            cols="12"
            ds="4"
            >
            <div class="subpage" v-if="item1">
              <h3>{{ item1.title }}</h3>
              <div v-html="item1 && item1.plcd_page_content"></div>
              <bxs-btn
                append-icon="arrow-right"
                text
                class="btn-triangle"
                to="/tecnologia/uniformita-e-precisione-di-dose">
                  {{ $translate('Vai alla sezione', activeLang) }}
                </bxs-btn>
            </div>

            </bxs-col>
            <bxs-col
            cols="12"
            ds="4"
            >
              <div class="subpage" v-if="item2">
                <h3>{{ item2.title }}</h3>
                <div v-html="item2 && item2.plcd_page_content"></div>
                <bxs-btn
                  append-icon="arrow-right"
                  text
                  class="btn-triangle"
                  to="/tecnologia/strati-sottili">
                    {{ $translate('Vai alla sezione', activeLang) }}
                  </bxs-btn>
              </div>
            </bxs-col>
            <bxs-col
            cols="12"
            ds="4"
            >
              <div class="subpage" v-if="item3">
                <h3>{{ item3.title }}</h3>
                <div v-html="item3 && item3.plcd_page_content"></div>
                <bxs-btn
                  append-icon="arrow-right"
                  text
                  class="btn-triangle"
                  to="/tecnologia/bassa-intensita">
                    {{ $translate('Vai alla sezione', activeLang) }}
                  </bxs-btn>
              </div>
            </bxs-col>
          </bxs-row>

          <!-- EN -->
          <bxs-row
          v-if="activeLang == 'en'">
            <bxs-col
            cols="12"
            ds="4"
            >
            <div class="subpage">
              <h3>Uniformity and Dose Precision</h3>
              <div>
                The Gammatom treatment can be adjusted using a three-dimensional Cartesian system (Oxyz), which accounts for the orientation of the material and its distance from the source. It also considers the shielding effect posed by other products that may be interposed between the source and the product itself
              </div>
              <bxs-btn
                append-icon="arrow-right"
                text
                class="btn-triangle"
                to="/en/technology/dosage-uniformity-and-precision/">
                  {{ $translate('Vai alla sezione', activeLang) }}
                </bxs-btn>
            </div>

            </bxs-col>
            <bxs-col
            cols="12"
            ds="4"
            >
            <div class="subpage">
              <h3>Thin layers</h3>
              <div>
                As mentioned, gamma rays are characterized by their high penetration capability; however, they dissipate part of their energy when they encounter elements of varying densities along their path. This principle underlies the Gammatom irradiation method: the decision to irradiate only thin layers to provide the most uniform dose possible to the products
              </div>
              <bxs-btn
                append-icon="arrow-right"
                text
                class="btn-triangle"
                to="/en/technology/thin-layers/">
                  {{ $translate('Vai alla sezione', activeLang) }}
                </bxs-btn>
            </div>
            </bxs-col>
            <bxs-col
            cols="12"
            ds="4"
            >
            <div class="subpage">
              <h3>Low Intensity</h3>
              <div>
                The ideal irradiation, in addition to the dose uniformity just described, must also be characterized by precision in dose administration, which Gammatom achieves by choosing to irradiate with a low-intensity source. Irradiation at a predetermined dose is based on the dwell time of the material in front of the radiation source
              </div>
              <bxs-btn
                append-icon="arrow-right"
                text
                class="btn-triangle"
                to="/en/technology/low-intensity">
                  {{ $translate('Vai alla sezione', activeLang) }}
                </bxs-btn>
            </div>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>
      <section class="faq">
        <bxs-layout>
          <bxs-row>
            <bxs-col
            cols="12"
            ds="12"
            >
              <h2>FAQ</h2>
              <div v-if="activeLang == 'it'">
                <bxs-collapse-item label="Ma il materiale diventa radioattivo dopo l’irraggiamento e va pertanto messo in quarantena?">
                  <p>La risposta è no, la soglia di attivazione della materia è 10 MeV che è l’energia minima che i raggi dovrebbero avere per attivare la materia e renderla a sua volta radioattiva. I raggi gamma da decadimento del cobalto 60 emettono due raggi con energia nota e sempre costante rispettivamente di 1.17 e 1.33 MeV che per cui non saranno mai in grado di rendere radioattivi i materiali e non è necessaria alcuna quarantena.</p>
                </bxs-collapse-item>
                <bxs-collapse-item label="Effettuate anche analisi di laboratorio quali test di sterilità o bioburden?">
                  <p>La risposta è no, siamo coloro che applicano il processo di irraggiamento per ottenere la sterilità di un prodotto, se effettuassimo anche le analisi di sterilità o le conte microbiche saremmo il controllore e controllato allo stesso tempo pertanto per scelta preferiamo lasciare la libertà ai nostri clienti di affidarsi a laboratori esterni totalmente indipendenti da noi per verificare il raggiungimento della sterilità tramite il processo di sterilizzazione.</p>
                </bxs-collapse-item>
                <bxs-collapse-item label="Mi garantite la sterilità?">
                  <p>L’irraggiatore per normativa non può essere responsabile della sterilità in quanto questa dipende da diversi fattori: la carica batterica presente sul prodotto, il processo di irraggiamento e la tenuta del packaging. Per poter dichiarare un prodotto sterile infatti è in genere necessario fare delle validazioni per la parte di laboratorio (determinazione del bioburden e test di sterilità), per la determinazione della dose di irraggiamento, per il processo di irraggiamento stesso ed infine per la tenuta del confezionamento.</p>
                </bxs-collapse-item>
                <bxs-collapse-item label="Aprite le scatole per effettuare il trattamento?">
                  <p>No, il trattamento avviene a scatola chiusa, i raggi gamma sono molto penetranti ed il vantaggio di questo tipo di sterilizzazione sta proprio nel fatto che si possa applicare ad imballo chiuso.</p>
                </bxs-collapse-item>
                <bxs-collapse-item label="Il prodotto si scalda durante il trattamento?">
                  <p>No, il processo in sé non scalda il prodotto, tuttavia all’interno della cella di irraggiamento c’è qualche grado in più rispetto all’esterno.</p>
                </bxs-collapse-item>
                <bxs-collapse-item label="E’ necessario un packaging particolare?">
                  <p>No, non ci sono requisiti particolari, va tenuto conto del fatto che alcune tipologie di plastica tendono ad ingiallire alle dosi più alte mentre il vetro imbrunisce già a dosi basse.</p>
                </bxs-collapse-item>
              </div>
              <div v-if="activeLang == 'en'">
                <bxs-collapse-item label="Does the material become radioactive after irradiation, and therefore needs to be quarantined?">
                  <p>The answer is no. The activation threshold for matter is 10 MeV, which is the minimum energy that rays must have to activate the material and make it radioactive in turn. Gamma rays from the decay of cobalt-60 emit two rays with known and always constant energies of 1.17 and 1.33 MeV, respectively, which are never sufficient to make the materials radioactive, and no quarantine is necessary.</p>
                </bxs-collapse-item>
                <bxs-collapse-item label="Do you also conduct laboratory analyses such as sterility tests or bioburden tests?">
                  <p>The answer is no. We are the ones who apply the irradiation process to achieve the sterility of a product. If we were also to perform sterility analyses or microbial counts, we would be both the controller and the controlled at the same time. Therefore, by choice, we prefer to give our clients the freedom to rely on external laboratories that are completely independent from us to verify the achievement of sterility through the sterilization process.</p>
                </bxs-collapse-item>
                <bxs-collapse-item label="Can you guarantee sterility?">
                  <p>By regulation, the irradiator cannot be held responsible for sterility as it depends on several factors: the bacterial load present on the product, the irradiation process, and the integrity of the packaging. To declare a product sterile, it is generally necessary to perform validations for the laboratory part (determination of bioburden and sterility testing), for determining the irradiation dose, for the irradiation process itself, and finally for the integrity of the packaging.</p>
                </bxs-collapse-item>
                <bxs-collapse-item label="Do you open the boxes to perform the treatment?">
                  <p>No, the treatment is carried out with the boxes closed. Gamma rays are highly penetrating, and the advantage of this type of sterilization is that it can be applied to closed packaging.</p>
                </bxs-collapse-item>
                <bxs-collapse-item label="Does the product heat up during the treatment?">
                  <p>No, the process itself does not heat the product; however, the temperature inside the irradiation chamber is slightly higher than outside.</p>
                </bxs-collapse-item>
                <bxs-collapse-item label="Is special packaging required?">
                  <p>No, there are no specific requirements. However, it should be noted that some types of plastic may yellow at higher doses, while glass can brown even at lower doses.</p>
                </bxs-collapse-item>
              </div>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>
      <!--
        <bxs-col
        cols="12"
        ds="6"
        class="bxs-col-no-padding bxs-bg-col"
        >
      -->

      <plcd-driving-banner
        :title="page.plcd_driving_banner_page_title"
        :to="page.plcd_driving_banner_link"
        :src="page.plcd_driving_banner_image"
      >
      </plcd-driving-banner>

  </div>
</template>

<script>
import { gsap } from 'gsap'
import { mapState } from 'vuex'
export default {
    name: 'tecnologia',
    data () {
      return {
        is_preloaded: false,
        is_loading: true,
        item1: null,
        item2: null,
        item3: null
      }
    },
    computed: {
      ...mapState({
        language: state => state.language,
        pages(state) {
          const languagePages = {
              it: state.pages,
              en: state.pagesEn
          }
          return languagePages[this.language] || state.pages
          },
          page() {
            const objArray = Object.values(this.pages)
            return objArray.filter(pages => pages.template === 'template-tecnologia.php')[0]
          },
          activeLang() {
            return this.$store.state.language
          }
      })
    },
    mounted() {
      this.$nextTick(this.start)

      // Mutation Observer for Locomotive
      this.observer = new MutationObserver(mutations => {
        for (const m of mutations) {
          const newValue = m.target.getAttribute(m.attributeName)
          this.$nextTick(() => {
            this.onClassChange(newValue, m.oldValue)
          })
        }
      })

      this.observer.observe(this.$refs.stickyBlock, {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ['class']
      })
    },

    methods: {
      onClassChange(classAttrs) {
          const classList = classAttrs.split(' ')
          if (classList.includes('is-inview')) {
            this.animateBlock(this.$refs.stickyBlock)
          } else {
            this.animateBlockBack(this.$refs.stickyBlock)
          }
        },
        beforeDestroy() {
          this.observer.disconnect()
        },
        animateBlock(block) {
          const blockMotion = gsap.timeline({})

          blockMotion
              .to(block, {
                  margin: '50px',
                  duration: 0.5,
                  ease: 'power3.easeOut'
              })
        },
        animateBlockBack(block) {
          const blockMotion = gsap.timeline({})

          blockMotion
              .to(block, {
                  margin: '0',
                  duration: 0.5,
                  ease: 'power3.easeOut'
              })
        },
        async start() {
            await this.getPage1()
            this.is_preloaded = true
            await this.getPage2()
            this.is_preloaded = true
            await this.getPage3()
            this.is_preloaded = true
            await this.getPage1En()
            this.is_preloaded = true
            await this.getPage2En()
            this.is_preloaded = true
            await this.getPage3En()
            this.is_preloaded = true
        },
        async getPage1() {
            this.is_loading = true

            try {
                this.item1 = await this.$store.dispatch('getPage', '/uniformita-e-precisione-di-dose')
            } catch (err) {
                alert(err)
            } finally {
                this.is_loading = false
            }
        },
        async getPage2() {
            this.is_loading = true

            try {
                this.item2 = await this.$store.dispatch('getPage', '/strati-sottili/')
            } catch (err) {
                alert(err)
            } finally {
                this.is_loading = false
            }
        },
        async getPage3() {
            this.is_loading = true

            try {
                this.item3 = await this.$store.dispatch('getPage', '/bassa-intensita')
            } catch (err) {
                alert(err)
            } finally {
                this.is_loading = false
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins.scss';
#contentSection{
  padding-bottom: 50px !important;
}

.stickyBlock{
  position: relative;
  padding: 70px 100px 0 100px;

  @include breakpoint(app_mobile) {
    display: none !important;
  }

  &__title{
    position: absolute;
    bottom: -120px;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0;
    font-size: 52px;
    border-bottom: 5px solid var(--color-primary);
    white-space: nowrap;

    .is-inview &{
      opacity: 1;
    }
  }
}
.tecnology-block{
  padding: 100px 100px;
  background: #f2f2f2;

  .subpage{
    h3{
      font-size: 23px;
      @include SideBold;
      letter-spacing: 0.006em;
      margin-bottom: 28px;
    }
    div{
      color: #111;
      line-height: 24px;
      height: 126px;
      overflow: hidden;
      margin-bottom: 28px;
    }
  }
}

.faq{
  padding:100px 0;
  max-width: 1200px;
  margin: 0 auto;

  h2{
    margin-bottom: 30px;
  }

  p{
    padding: 10px 0 0 50px;
  }
  .collapse-item-comp{
    background: #eee;
  }
}
</style>
