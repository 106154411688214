<template>
  <div class="page">
      <!-- HEADING 1 IMAGE BIG -->

      <plcd-page-heading
        :title="page.title"
        :subtitle="page.plcd_page_subtitle"
        :svgbg="true"
      >
      </plcd-page-heading>

      <section class="page-content" id="contentSection">
        <bxs-layout>
          <bxs-row>
            <bxs-col
            cols="12"
            ds="12"
            >
            <div class="page-content__text page-content__text--inverted" v-html="page && page.plcd_page_content">
            </div>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>
      <!--
        <bxs-col
        cols="12"
        ds="6"
        class="bxs-col-no-padding bxs-bg-col"
        >
      -->

      <plcd-driving-banner
        :title="page.plcd_driving_banner_page_title"
        :to="page.plcd_driving_banner_link"
        :src="page.plcd_driving_banner_image"
      >
      </plcd-driving-banner>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'cookie-policy',
    computed: {
      ...mapState({
        language: state => state.language,
        pages(state) {
          const languagePages = {
              it: state.pages,
              en: state.pagesEn
          }
          return languagePages[this.language] || state.pages
          },
          page() {
            const objArray = Object.values(this.pages)
            return objArray.filter(pages => pages.template === 'template-cookie-policy.php')[0]
          },
          activeLang() {
            return this.$store.state.language
          }
      })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins.scss';

.stickyBlock{
  position: relative;
  padding: 70px 100px 0 100px;

  @include breakpoint(app_mobile) {
    display: none !important;
  }

  &.is-inview{
  }

  &__title{
    position: absolute;
    bottom: -120px;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0;
    font-size: 52px;
    border-bottom: 5px solid var(--color-primary);
    white-space: nowrap;

    .is-inview &{
      opacity: 1;
    }
  }
}

</style>
