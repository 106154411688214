<template>
  <div class="page">
      <!-- HEADING 1 IMAGE BIG -->

      <plcd-page-heading
        :title="page.title"
        :subtitle="page.plcd_page_subtitle"
        :svgbg="true"
      >
      </plcd-page-heading>

      <section class="page-content" id="contentSection">
        <bxs-layout>
          <bxs-row>
            <bxs-col
            cols="12"
            ds="12"
            >
              <div class="page-content__text">
                <div class="media-list" v-if="elements">
                  <div class="media-list__element" v-for="(element, i) in elements" :key="i">
                    <bxs-col
                      cols="3"
                      ds="3"
                    >
                      <div class="media-list__element__img">
                        <a :href="element.plcd_media_element_link" target="_blank">
                          <img :src="element.plcd_media_element_img" alt="">
                        </a>
                      </div>
                    </bxs-col>
                    <bxs-col
                      cols="9"
                      ds="9"
                    >
                      <div class="media-list__element__content">
                        <a :href="element.plcd_media_element_link" target="_blank">
                          <h2>{{ element.plcd_media_element_title }}</h2>
                        </a>
                        <bxs-btn
                        append-icon="arrow-right"
                        text
                        class="btn-triangle"
                        :href="element.plcd_media_element_link">
                          {{ $translate('Visualizza ', activeLang) }}
                        </bxs-btn>
                      </div>
                    </bxs-col>
                  </div>
                </div>
              </div>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>
      <!--
        <bxs-col
        cols="12"
        ds="6"
        class="bxs-col-no-padding bxs-bg-col"
        >
      -->

      <plcd-driving-banner
        :title="page.plcd_driving_banner_page_title"
        :to="page.plcd_driving_banner_link"
        :src="page.plcd_driving_banner_image"
      >
      </plcd-driving-banner>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'press-releases',
    computed: {
      ...mapState({
        language: state => state.language,
        pages(state) {
          const languagePages = {
              it: state.pages,
              en: state.pagesEn
          }
          return languagePages[this.language] || state.pages
          },
          page() {
            const objArray = Object.values(this.pages)
            return objArray.filter(pages => pages.template === 'template-press.php')[0]
          },
          elements() {
            return this.page.plcd_media_element
          },
          activeLang() {
            return this.$store.state.language
          }
      })
    },

    mounted() {
    }
}
</script>

<style lang="scss">
</style>
