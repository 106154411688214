<template>
<a v-if="langs[0]" :href="langs[0].url">
  <div class="trigger" v-if="langs[0].code === 'en'">
    <span>IT</span>
    <span>EN</span>
  </div>
  <div class="trigger" v-else>
    <span>EN</span>
    <span>IT</span>
  </div>
</a>
</template>

<script>
import Http from '@/assets/libs/Http'
export default {
    name: 'bxs-lang-selector',
    data () {
        return {
            langs: {}
        }
    },
    created() {
      this.getLangs()
    },
    methods: {
      async getLangs () {
          const wp = new Http(process.env.VUE_APP_WP_API)
          const langs = await wp.getLanguageSelector(this.$route.path)
          const filteredItems = langs.filter(lang => lang.active === false)
          this.langs = filteredItems
          return this.langs
      }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/mixins.scss';
.link-wrapper{
  @include SideRegular;
  color: #333;
  text-decoration: none !important;
  text-transform: uppercase;
  letter-spacing: .02em;
  height: 100%;
  text-align: center;
  position: static;
  text-decoration: none;

  a{
    padding: 19.235%;
    text-decoration: none;
    position: static;
    font-size: 15px;

    .trigger{
      display: flex;
      flex-flow: column;
      flex: none;
      justify-content: flex-start;
      overflow: hidden;
      height: 16px;
      margin: 10px 0 10px;

      span{
        transition: all .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      }
    }
  }

  > a{
    width: 100%;
    height: 100%;
  }

  a:hover{
    .trigger{
      span{
        transform: translate3d(0, -100%, 0);
      }
    }
  }
}

.lang-selector{
  position: absolute !important;
  right: -63px;
  top: 30px;
  border: none !important;
  height: 50px;

  .trigger{
    width: 50px;
  }

  .lang-label{
    @include SideRegular;
    text-transform: uppercase;
    color: white;
    text-decoration: none !important;
    text-transform: uppercase;
    letter-spacing: .02em;
    text-align: center;
    text-decoration: none;
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }

/*
  > a{
      width: 30px;
      height: 20px;
      display: block;
      background-size: cover !important;
      margin-bottom: 40px;

    &.it{
      display: none;
      background:transparent url('@/assets/img/it.svg') center center no-repeat;
    }
    &.en{
      display: none;
      background:transparent url('@/assets/img/en.svg') center center no-repeat;
    }
  }
*/
}
</style>
