<template>
  <swiper
  v-if="is_preloaded"
  :slides-per-view="1"
  :modules="modulesNews"
  :navigation="true"
  :loop="true"
  :effect="'fade'"
  :fadeEffect="{
    crossFade: true
  }"
  @swiper="onSwiper"
  @slideChange="onSlideChange">
    <div class="swiper-custom-navigation"></div>
    <swiper-slide
      v-for="item in data_page.docs" :key="item.id"
    >
      <div class="news">
        <bxs-figure class="news--figure" :src="item.thumb_url" ratio="16/9" height="340">
        </bxs-figure>
        <div class="news--content">
            <h3 class="news--content--title">
              <router-link :to="item.to">
                {{ item.title }}
              </router-link>
            </h3>
            <div class="news--content--paragraph" v-html="item.content">
            </div>
            <div class="news--content--category">
              <div>News</div>
            </div>
            <bxs-btn
            append-icon="arrow-right"
            :to="item.to"
            v-if="activeLang == 'it'"
            text>{{ $translate("Approfondisci", activeLang) }}</bxs-btn>
            <bxs-btn
            append-icon="arrow-right"
            :to="item.to"
            v-if="activeLang == 'en'"
            text>{{ $translate("Read More", activeLang) }}</bxs-btn>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>
<script>

import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, EffectFade, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'
import { mapState } from 'vuex'

export default {
    name: 'bxs-latest-news',
    data () {
      return {
        is_preloaded: false,
        is_busy: false,
        scroll: null,
        data_page: {
          category: null,
          docs: [],
          pagination: {
              page: 1
          }
        }
      }
    },
    computed: {
      ...mapState({
        language: state => state.language,
          activeLang() {
            return this.$store.state.language
          }
      })
    },
    components: {
      Swiper,
      SwiperSlide
    },
    setup () {
      const onSwiper = (swiper) => {
        // console.log(swiper)
      }
      const onSlideChange = () => {
        // console.log('slide change')
      }
      return {
        onSwiper,
        onSlideChange,
        modules: [Autoplay],
        modulesNews: [EffectFade, Navigation]
      }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        async start () {
            await this.getDocs()
            this.is_preloaded = true
        },
        async getDocs (page = 1, slug) {
            this.is_busy = true

            try {
                const {
                    category,
                    docs,
                    pagination
                } = await this.$store.dispatch('getNews', {
                    limit: 3,
                    page: page,
                    lang: this.activeLang,
                    ...this.$route.query
                })

                this.data_page.category = category

                if (page === 1) this.data_page.docs = docs
                else this.data_page.docs.push(...docs)

                this.data_page.pagination = pagination
            } catch (err) {
                alert(err)
            } finally {
                this.is_busy = false
                this.is_preloaded = true
            }
        }
    },
    beforeRouteUpdate (to, from) {
      this.getDocs(1, to.params.slug)
    }
}

</script>
<style scoped lang="scss">
  @import '@/assets/styles/mixins.scss';

.news-swiper{
  .bxs-col-12 {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 15px;
    max-width: 98%;
    margin: 0 18px;
  }

  .news--content--paragraph{
    height: 58px;
    overflow: hidden;
  }

  .news--content{
    padding-right: 10% !important;
  }

  .news--content--title{
    @include MainRegular;

    a{
      @include MainRegular;
    }
  }

  .news{
    display: flex;
    border: none;
    padding: 0 !important;

    a{
      text-decoration: none !important;
    }

    .figure{
      width: 32%;
      border-radius: 3px;
    }

    .news--content{
      width: 58%;
      padding: 50px;
    }

    .news--content--title{
      transition: all .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      text-decoration: none !important;
    }

    .news--content--title:hover{
        color: var(--color-primary);
    }
  }
}
</style>
