<template>
    <footer ref="footer" :class="['app--footer bxs-footer', { 'bxs-footer-mobile': $store.state.is_mobile }]" data-scroll>
      <section class="footer-heading">
        <div class="footer-heading--left">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="97.000000pt" height="116.000000pt" viewBox="0 0 97.000000 116.000000"
          preserveAspectRatio="xMidYMid meet">

          <g transform="translate(0.000000,116.000000) scale(0.100000,-0.100000)"
          fill="#ff5f00" stroke="none">
          <path d="M689 1112 c-8 -11 -56 -70 -107 -131 l-94 -111 -100 125 -100 125
          -135 0 c-105 0 -133 -3 -127 -12 5 -7 79 -102 166 -211 l158 -197 -61 -82
          c-96 -129 -120 -187 -120 -291 -1 -176 139 -311 321 -311 59 0 85 5 124 23 64
          31 118 88 153 159 23 48 27 70 27 142 0 102 -15 142 -103 263 -34 46 -61 88
          -61 94 0 8 153 194 293 355 26 30 47 59 47 64 0 5 -60 11 -133 12 -123 3 -135
          2 -148 -16z m-149 -664 c54 -77 61 -108 34 -159 -18 -36 -73 -62 -112 -53 -34
          8 -79 49 -86 80 -4 13 0 39 8 58 13 31 93 146 102 146 2 0 26 -32 54 -72z"/>
          </g>
          </svg>
          <div class="footer-text-logo">gammatom<br>
            <span class="footer-claim">Tailor Made <strong>Gamma Irradiation</strong></span>
          </div>
        </div>
        <div class="footer-heading--right">
        </div>
      </section>
      <section class="footer-body">
        <div>
          <ul class="footer-main-menu" v-if="activeLang == 'it'">
            <li><router-link to="/about-us">About Us</router-link></li>
            <li><router-link to="/tecnologia">Tecnologia</router-link></li>
            <li><router-link to="/principi-attivi">Principi Attivi</router-link></li>
            <li><router-link to="/campi-di-applicazione">Applicazioni</router-link></li>
            <li><router-link to="/certificazioni">Certificazioni</router-link></li>
          </ul>
          <ul class="footer-main-menu" v-if="activeLang == 'en'">
            <li><router-link to="/en/about-us">About Us</router-link></li>
            <li><router-link to="/en/technology">Technology</router-link></li>
            <li><router-link to="/en/active-ingredients">Active Ingredients</router-link></li>
            <li><router-link to="/en/application-fields">Applications</router-link></li>
            <li><router-link to="/en/certifications">Certifications</router-link></li>
          </ul>
        </div>
        <div class="footer-tail footer-tail--mobile">
          <div>
            <ul class="footer-tail-menu" v-if="activeLang == 'it'">
              <li><router-link to="/press-releases">News</router-link></li>
              <li><router-link to="/press">Press</router-link></li>
              <li><router-link to="/media">Media</router-link></li>
              <li><router-link to="/storytelling">Storytelling</router-link></li>
            </ul>
            <ul class="footer-tail-menu" v-if="activeLang == 'en'">
              <li><router-link to="/en/press-releases">News</router-link></li>
              <li><router-link to="/en/press">Press</router-link></li>
              <li><router-link to="/en/media">Media</router-link></li>
              <li><router-link to="/en/storytelling">Storytelling</router-link></li>
            </ul>
          </div>
          <div>
            <ul class="footer-tail-menu">
              <li><a href="mailto:info@gammatom.it">Contacts</a></li>
              <li><a href="https://www.facebook.com/Gammatom.it/" target="_blank">Facebook</a></li>
              <li><a href="https://www.linkedin.com/company/gammatom-srl/" target="_blank">LinkedIn</a></li>
              <li><a href="https://www.youtube.com/channel/UCsAXLRvM0bCHsfIfr3zZOuQ" target="_blank">YouTube</a></li>
            </ul>
          </div>
        </div>
        <div>
          <div class="form-wrapper footer-form">
            <div class="form-wrapper__title">{{ $translate('Mettiti in contatto con noi', activeLang) }}</div>
            <form action="" method="" v-if="activeLang == 'it'">
              <div class="form-row"><input type="text" placeholder="Nome Completo" value=""></div>
              <div class="form-row"><input type="email" placeholder="E-mail" value=""></div>
              <div class="form-row"><input type="text" placeholder="Azienda" value=""></div>
              <div class="form-row form-row--last"><textarea placeholder="Messaggio"></textarea></div>
              <div class="privacy-box-container">
                <label>
                  <input type="checkbox"><small class="ml-3"> {{ $translate("Per presa visione dell'informativa privacy ai sensi del RE 2016/679", activeLang) }}</small>
                </label>
              </div>
              <div class="buttons-set buttons-set--footer">
                <bxs-btn
                  append-icon="arrow-right"
                  class="button-white"
                  text>
                    Invia Richiesta
                </bxs-btn>
              </div>
            </form>
            <form action="" method="" v-if="activeLang == 'en'">
              <div class="form-row"><input type="text" placeholder="Full Name" value=""></div>
              <div class="form-row"><input type="email" placeholder="E-mail" value=""></div>
              <div class="form-row"><input type="text" placeholder="Company" value=""></div>
              <div class="form-row form-row--last"><textarea placeholder="Message"></textarea></div>
              <div class="privacy-box-container">
                <label>
                  <input type="checkbox"><small class="ml-3"> {{ $translate("By submitting this form, you acknowledge that you have read the privacy policy in accordance with Regulation (EU) 2016/679.", activeLang) }}</small>
                </label>
              </div>
              <div class="buttons-set buttons-set--footer">
                <bxs-btn
                  append-icon="arrow-right"
                  class="button-white"
                  text>
                    Send Request
                </bxs-btn>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section class="footer-tail">
        <div>
          <ul class="footer-tail-menu" v-if="activeLang == 'it'">
            <li><router-link to="/press-releases">News</router-link></li>
            <li><router-link to="/press">Press</router-link></li>
            <li><router-link to="/media">Media</router-link></li>
            <li><router-link to="/storytelling">Storytelling</router-link></li>
          </ul>
          <ul class="footer-tail-menu" v-if="activeLang == 'en'">
            <li><router-link to="/en/press-releases">News</router-link></li>
            <li><router-link to="/en/press">Press</router-link></li>
            <li><router-link to="/en/media">Media</router-link></li>
            <li><router-link to="/en/storytelling">Storytelling</router-link></li>
          </ul>
        </div>
        <div>
          <ul class="footer-tail-menu">
            <li><a href="mailto:info@gammatom.it">Contacts</a></li>
            <li><a href="https://www.facebook.com/Gammatom.it/" target="_blank">Facebook</a></li>
            <li><a href="https://www.linkedin.com/company/gammatom-srl/" target="_blank">LinkedIn</a></li>
            <li><a href="https://www.youtube.com/channel/UCsAXLRvM0bCHsfIfr3zZOuQ" target="_blank">YouTube</a></li>
          </ul>
        </div>
      </section>
      <section class="footer-copyright">
        <div>
          © Copyright {{ year }} Gammatom Srl. All Right Reserved | PI 03165630157
        </div>
        <div>
          <a href="https://drive.google.com/file/d/1uLYIM-uev2D-6ERv1ACxaHiV7_bdWUtO/view?usp=sharing" target="_blank">Privacy Policy</a> | <router-link target="_blank" to="/cookie-policy" v-if="activeLang == 'it'">Cookie Policy</router-link> <router-link target="_blank" to="/en/cookie-policy" v-if="activeLang == 'en'">Cookie Policy</router-link> <router-link class="whistle" to="/whistleblowing" v-if="activeLang == 'it'">Whistleblowing</router-link> <router-link class="whistle" to="/en/whistleblowing" v-if="activeLang == 'en'">Whistleblowing</router-link>
        </div>
        <div>
          <a href="https://online.fliphtml5.com/dcid/oqmj/#p=1" target="_blank">Company Profile</a>
        </div>
      </section>
    </footer>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'bxs-footer',
    data () {
        return {
            year: new Date().getFullYear()
        }
    },

    computed: {
      ...mapState({
        language: state => state.language
      }),
      activeLang() {
        return this.$store.state.language
      }
    },

    mounted () {
      // Mutation Observer for Locomotive
      this.observer = new MutationObserver(mutations => {
        for (const m of mutations) {
          const newValue = m.target.getAttribute(m.attributeName)
          this.$nextTick(() => {
            this.onClassChange(newValue, m.oldValue)
          })
        }
      })

      this.observer.observe(this.$refs.footer, {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ['class']
      })
    },

    methods: {
      linkPreventDef(event) {
        event.preventDefault()
      },
      onClassChange(classAttrs) {
          const classList = classAttrs.split(' ')
          if (classList.includes('is-inview')) {
            if (this.$route.path === '/' || this.$route.path === '/home' || this.$route.path === '/en' || this.$route.path === '/en/' || this.$route.path === 'en' || this.$route.path === 'en/') {
              this.$root.resize()
            }
            console.log('FOOTER RESIZE')
            this.observer.disconnect()
          }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mq.scss';
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

$footerGap: 100px;
$footerWhite: #dedede;

footer{
    height: 100vh;
    position: relative;
    width: 100%;
    background-color: #191b29;
    flex-flow: row nowrap;
    align-items: center;
    color: $footerWhite;
    justify-content: space-between;
    z-index: var(--layer-navbar);
    padding-top: 48px;
    font-size: 17px;

    @include breakpoint(low_height) {
      height: 130vh;
    }

    @include breakpoint(app_mobile) {
      height: auto;
    }

    .app-megamenu-open &{
      z-index: 1;
    }

    .footer-heading{
      padding: 0 48px 92px;

      @include breakpoint(app_mobile) {
        padding: 12px 30px 12px;
      }

      &--left{
          display: flex;
        svg{
          width: 40px;
          height: 50px;
        }

        .footer-text-logo{
          @include MainHeavy;
          font-size: 34px;
          color: var(--color-primary);
          margin-top: 14px;
          margin-left: 1px;
        }
        .footer-claim{
          display: none;
          color: var(--color-primary);
          text-transform: uppercase;
          font-size: 16px;
          letter-spacing: .1em;
          margin-top:-10px;

          strong{
            color: white;
          }
        }
      }

      &--right{
        display: none;
      }
    }

    .footer-body{
      padding: 0 150px 9.5vh;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $footerGap;

      @include breakpoint(desktop) {
        padding-bottom: 32px;
        padding-left: 50px;
      }

      @include breakpoint(app_mobile) {
        display: flex;
        flex-direction: column;
        gap: 0;
        padding-right: 30px;
        padding-left: 30px;
      }

      > div:first-child{
        @include breakpoint(app_mobile) {
          padding-bottom: 30px;
        }
      }

      .footer-form{
        @include breakpoint(desktop) {
          max-width: 100%;
        }

        @include breakpoint(app_mobile) {
          padding-top: 22px;
          padding-bottom: 22px;
        }

        .form-wrapper__title{
          font-size: 24px;
        }
      }

      .footer-main-menu{
        li{
          margin-bottom: 1vh;

          a{
            @include SideRegular;
            font-size: 52px;
            letter-spacing: .03em;

            @include breakpoint(app_mobile) {
              font-size: 36px;
            }
          }
        }
      }
    }

    .footer-tail{
      padding: 0 150px 48px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $footerGap;

      @include breakpoint(desktop) {
        padding-left: 50px;
      }

      @include breakpoint(app_mobile) {
        display: none;
      }

      li{
        margin-bottom: 12px;

        a{
          font-size: 18px;
        }
      }

      &--mobile{
        display: none;

        @include breakpoint(app_mobile) {
          display: grid;
          padding-left: 0;
          padding-bottom: 22px;
        }
      }
    }

    &.bxs-footer-mobile{
      padding-top: 28px;
    }

    a{
      transition: all .5s ease;
      color: $footerWhite;
      text-decoration: none;

      &:hover{
        opacity: .5;
      }
    }

    .buttons-set{
      margin-top: 20px;
    }
}

.footer-copyright{
  @include SideRegular;
  width: 100%;
  background-color: hsla(0,0%,100%,.1);
  display: grid;
  grid-template-columns: 2.5fr 2fr 0.5fr;
  gap: 20px;
  padding: 20px 48px 20px 150px;
  position: absolute;
  bottom: 0;
  left: 0;

  @include breakpoint(desktop) {
    padding-left: 50px;
  }

  @include breakpoint(app_mobile) {
    position: static;
    font-size: 14px;
    grid-template-columns: 2.5fr 2fr;
    padding-right: 30px;
    padding-left: 30px;
  }

  a{
    color: $footerWhite;
    text-decoration: none;
    white-space: nowrap;
  }
}
.whistle{
  background: var(--color-primary);
  display: inline-block;
  padding: 6px 15px;
  margin-left: 5px;
  color: white !important;
  margin-top: -5px;
}
</style>
