// comps
import Layout from './layout/Layout.vue'
import Row from './layout/Row/Row.vue'
import Col from './layout/Col/Col.vue'

import Btn from './Btn.vue'
import Chip from './Chip.vue'
import ChipGroup from './ChipGroup.vue'
import Icon from './Icon.vue'
import Menu from './Menu.vue'
import List from './List.vue'
import ListItem from './ListItem.vue'
import CollapseItem from './CollapseItem.vue'
import Spacer from './Spacer.vue'
import Toolbar from './Toolbar.vue'
import Card from './Card.vue'
import Figure from './media/FigureComp.vue'
import Loader from './Loader.vue'

import Form from './form/Form'
import BxsSwitchField from './form/BxsSwitchField.vue'
import BxsTextField from './form/TextField.vue'
import BxsTextareaField from './form/BxsTextareaField.vue'

import DataLazy from './DataLazy.vue'

import Logo from './Logo.vue'
import Footer from './layout/Footer.vue'
import TopNavigation from './TopNavigation.vue'

// libs
import Toast from 'vue-toastification'

// PLCD
import FluidHero from './FluidHero.vue'
import CursorFilter from './CursorFilter.vue'
import TextExtrovert from './TextExtrovert.vue'
import TriangleMotion from './TriangleMotion.vue'
import PageHeading from './PageHeading.vue'
import DrivingBanner from './DrivingBanner.vue'
import MegaMenu from './MegaMenu.vue'
import LangSelector from './LangSelector.vue'
import LatestNews from './LatestNews.vue'

import 'vue-toastification/dist/index.css'

export default function createComp(app) {
    app.component('logo', Logo)
    app.component('bxs-top-nav', TopNavigation)

    app.component('bxs-layout', Layout)
    app.component('bxs-row', Row)
    app.component('bxs-col', Col)
    app.component('bxs-footer', Footer)

    app.component('bxs-toolbar', Toolbar)
    app.component('bxs-card', Card)
    app.component('bxs-data-lazy', DataLazy)
    app.component('bxs-figure', Figure)

    app.component('bxs-btn', Btn)
    app.component('bxs-menu', Menu)
    app.component('bxs-list', List)
    app.component('bxs-list-item', ListItem)
    app.component('bxs-collapse-item', CollapseItem)

    app.component('bxs-icon', Icon)
    app.component('bxs-chip', Chip)
    app.component('bxs-chip-group', ChipGroup)
    app.component('bxs-loader', Loader)

    app.component('bxs-form', Form)
    app.component('bxs-switch-field', BxsSwitchField)
    app.component('bxs-text-field', BxsTextField)
    app.component('bxs-textarea-field', BxsTextareaField)

    app.component('bxs-spacer', Spacer)

    app.component('plcd-text-extrovert', TextExtrovert)
    app.component('plcd-fluid-hero', FluidHero)
    app.component('plcd-cursor-filter', CursorFilter)
    app.component('plcd-triangle-motion', TriangleMotion)
    app.component('plcd-page-heading', PageHeading)
    app.component('plcd-driving-banner', DrivingBanner)
    app.component('plcd-mega-menu', MegaMenu)
    app.component('bxs-lang-selector', LangSelector)
    app.component('bxs-latest-news', LatestNews)

    app.use(Toast, {
        position: 'bottom-center',
        timeout: 2500,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        newestOnTop: false,
        transition: 'Vue-Toastification__fade',
        bodyClassName: ['bxs-notify'],
        maxToasts: 1,
        showCloseButtonOnHover: true,
        hideProgressBar: true,
        draggable: true
    })
}
