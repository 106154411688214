<template>
  <div class="page">
      <!-- HEADING 1 IMAGE BIG -->

      <plcd-page-heading v-if="!is_loading"
        :title="item.title"
        subtitle="news"
        :svgbg="true"
        :col2="true"
        :src="item.thumb_url"
      >
      </plcd-page-heading>

      <section class="page-content page-content--news">
        <bxs-layout class="bxs-layout-fluid bxs-layout-vertical-padded" style="margin-bottom: 0">
          <bxs-row>
            <bxs-col
            cols="12"
            ds="12"
            class="bxs-col-no-padding"
            >
            <div class="page-content__text page-content__text--news">
              <div v-html="item && item.content"></div>
              <p class="hashtags" v-if="tags">
                <span v-for="(tags, i) in tags" :key="i">{{ tags.name  }}</span>
              </p>
            </div>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>

      <!--
      <section class="news-wrapper" style="margin-bottom: 112px">
        <bxs-layout>
          <h2>Latest News & Events</h2>
          <bxs-row>
            <bxs-col cols="12" ds="4">
              <router-link
                to="/news/collaborazioni-prestigiose-politecnico-torino"
                class="news"
                >
                <bxs-figure class="news--figure" src="/img/news1.png" ratio="16/9">
                </bxs-figure>
                <div class="news--content">
                    <h3 class="news--content--title">Collaborazioni prestigiose: Politecnico Torino</h3>
                    <div class="news--content--paragraph">
                      Le sinergie con partners istituzionali nell'ambito della ricerca scientifica,
                      sono un elemento determinante per la conferma della capacità e validità dei nostri servizi.
                      In questo caso citiamo con orgoglio una collaborazione con il Politecnico di Torino.
                    </div>
                    <bxs-btn
                    append-icon="arrow-right"
                    text>Approfondisci</bxs-btn>
                </div>
              </router-link>
            </bxs-col>
            <bxs-col cols="12" ds="4">
              <router-link
                to="/news/1672"
                class="news"
                >
                <bxs-figure class="news--figure" src="/img/news2.png" :animateHover="true" ratio="16/9">
                </bxs-figure>
                <div class="news--content">
                    <h3 class="news--content--title">Collaborazioni prestigiose: Politecnico Torino</h3>
                    <div class="news--content--paragraph">
                      Le sinergie con partners istituzionali nell'ambito della ricerca scientifica,
                      sono un elemento determinante per la conferma della capacità e validità dei nostri servizi.
                      In questo caso citiamo con orgoglio una collaborazione con il Politecnico di Torino.
                    </div>
                    <bxs-btn
                    append-icon="arrow-right"
                    text>Approfondisci</bxs-btn>
                </div>
              </router-link>
            </bxs-col>
            <bxs-col cols="12" ds="4">
              <router-link
                to="/news/universita-degli-studi-dellaquila-e-gammatom-una-collaborazione-costruttiva/"
                class="news"
                >
                <bxs-figure class="news--figure" src="/img/news3.png" ratio="16/9">
                </bxs-figure>
                <div class="news--content">
                    <h3 class="news--content--title">Collaborazioni prestigiose: Politecnico Torino</h3>
                    <div class="news--content--paragraph">
                      Le sinergie con partners istituzionali nell'ambito della ricerca scientifica,
                      sono un elemento determinante per la conferma della capacità e validità dei nostri servizi.
                      In questo caso citiamo con orgoglio una collaborazione con il Politecnico di Torino.
                    </div>
                    <bxs-btn
                    append-icon="arrow-right"
                    text>Approfondisci</bxs-btn>
                </div>
              </router-link>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>
      -->

      <plcd-driving-banner
        title="News"
        to="/press-releases"
        src="https://gammatom.sviluppo-web.net/wp-content/uploads/2021/05/Schermata-2021-05-05-alle-13.30.54.png"
      >
      </plcd-driving-banner>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'news',
    data () {
        return {
            is_preloaded: false,
            is_loading: true,
            item: null,
            tags: null
        }
    },
    computed: {
      ...mapState({
        language: state => state.language,
          activeLang() {
            return this.$store.state.language
          }
      })
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        async start (slug) {
            await this.getDoc(slug)
            this.is_preloaded = true
        },
        async getDoc (slug) {
            this.is_loading = true

            try {
                if (this.activeLang === 'it') {
                  this.item = await this.$store.dispatch('getSingleNews', slug || this.$route.params.slug)
                } else if (this.activeLang === 'en') {
                  this.item = await this.$store.dispatch('getSingleNewsEn', slug || this.$route.params.slug)
                }
                if (this.item.tags.length > 0) {
                  this.tags = this.item.tags
                }
            } catch (err) {
                alert(err)
            } finally {
                this.is_loading = false
            }
        }
    },
    beforeRouteUpdate (to) {
        this.getDoc(to.params.slug)
    },
    beforeUnmount () {
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins.scss';
  .page-content.page-content--news{
    .bxs-layout{
      margin-top: 0;
    }

    .page-content__text--news{
      background: rgba(230, 230, 230, 0.45);
      padding-top: 92px;
      padding-bottom: 92px;
      padding-left: 450px;
      padding-right: 450px;
      margin: 0 auto;
      border-bottom: 1px solid #dedede;

      @include breakpoint(desktop) {
        padding-top: 68px !important;
        padding-bottom: 68px !important;
        padding-left: 70px !important;
        padding-right: 70px !important;
      }

      @include breakpoint(app_mobile) {
        padding-top: 68px !important;
        padding-bottom: 68px !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
      }
    }

    .hashtags{
      margin-top: 62px;
      @include MainItalic;
      > span{
        margin-right: 8px;
        font-size: 14px;
      }
    }
  }

  /* News Element */

</style>
