export default {
  'Vai alla sezione': 'Vai alla sezione',
  'Mettiti in contatto con noi': 'Mettiti in contatto con noi',
  'Questo sito non utilizza cookie di profilazione. Si fa invece ricorso a cookie tecnici, anche di terze parti, al fine di migliorare l\'esperienza di navigazione. Cliccando sul tasto “Rifiuta”, chiuderai il banner senza prestare il consenso ad alcun cookie, ad eccezione di quelli necessari al corretto funzionamento del sito.': 'Questo sito non utilizza cookie di profilazione. Si fa invece ricorso a cookie tecnici, anche di terze parti, al fine di migliorare l\'esperienza di navigazione. Cliccando sul tasto “Rifiuta”, chiuderai il banner senza prestare il consenso ad alcun cookie, ad eccezione di quelli necessari al corretto funzionamento del sito.',
  'Accetta ': 'Accetta',
  'Rifiuta ': 'Rifiuta',
  'Impostazioni cookie': 'Impostazioni cookie',
  'Impostazioni ': 'Impostazioni',
  'Cookie strettamente necessari': 'Cookie strettamente necessari',
  'I cookie strettamente necessari sono cookie tecnici, che non raccolgono dati, necessari per la corretta visualizzazione del sito web.': 'I cookie strettamente necessari sono cookie tecnici, che non raccolgono dati, necessari per la corretta visualizzazione del sito web.',
  'Cookie di terze parti': 'Cookie di terze parti',
  'Google Maps è un servizio di visualizzazione di mappe gestito da Google che aiuta i visitatori nella localizzazione dell\'azienda. Questo servizio è fornito utilizzando esclusivamente cookie tecnici.': 'Google Maps è un servizio di visualizzazione di mappe gestito da Google che aiuta i visitatori nella localizzazione dell\'azienda. Questo servizio è fornito utilizzando esclusivamente cookie tecnici.',
  'Salva le modifiche': 'Salva le modifiche',
  'Attivo ': 'Attivo'
}
