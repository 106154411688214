<template>
  <div class="page">
      <!-- HEADING 1 IMAGE BIG -->

      <plcd-page-heading
        :title="page.title"
        :subtitle="page.plcd_page_subtitle"
        :svgbg="true"
      >
      </plcd-page-heading>

      <section class="page-content" id="contentSection">
        <bxs-layout>
          <bxs-row>
            <bxs-col
            cols="12"
            ds="12"
            >
            <div class="page-content__text">
              <bxs-row>
                <bxs-col
                cols="12"
                ds="3"
                >
                  <h2 class="contact-title">{{ $translate('Contatti telefonici', activeLang) }}</h2>
                  <div class="contact-content">{{ $translate('Centralino ', activeLang) }} <a href="tel:+39031976035">+39 031 976035</a></div>
                  <div class="contact-content">{{ $translate('Customer Service', activeLang) }} <a href="tel:+39031977379">+39 031 977379</a></div>
                </bxs-col>
                <bxs-col
                cols="12"
                ds="3"
                >
                  <h2 class="contact-title">{{ $translate('Contatto e-mail', activeLang) }}</h2>
                  <div class="contact-content"><a href="mailto:info@gammatom.it">info@gammatom.it</a></div>
                </bxs-col>
                <bxs-col
                cols="12"
                ds="3"
                >
                  <h2 class="contact-title">{{ $translate('Sede legale', activeLang) }}</h2>
                  <div class="contact-content">Via XXIV Maggio, 14</div>
                  <div class="contact-content">22070 Guanzate (CO) - Italy</div>
                </bxs-col>
                <bxs-col
                cols="12"
                ds="3"
                >
                  <h2 class="contact-title">{{ $translate('Orari di apertura', activeLang) }}</h2>
                  <div class="contact-content">08:30 - 12:00</div>
                  <div class="contact-content">14:00 - 17:00</div>
                </bxs-col>
              </bxs-row>
              <bxs-row class="contact-second-section">
                <bxs-col
                cols="12"
                ds="6"
                >
                  <div class="form-wrapper contact-page-form">
                    <h2 class="contact-title text-center">{{ $translate('Contattaci ', activeLang) }}</h2>
                    <form action="" method="" v-if="activeLang == 'it'">
                      <div class="form-row"><input type="text" placeholder="Nome Completo" value=""></div>
                      <div class="form-row"><input type="email" placeholder="E-mail" value=""></div>
                      <div class="form-row"><input type="text" placeholder="Azienda" value=""></div>
                      <div class="form-row form-row--last"><textarea placeholder="Messaggio"></textarea></div>
                      <div class="privacy-box-container">
                        <label>
                          <input type="checkbox"><small> Per presa visione dell'informativa privacy ai sensi del RE 2016/679</small>
                        </label>
                      </div>
                      <i>Le richieste inviate tramite il presente form saranno gestite entro massimo 48 ore lavorative dalla ricezione.</i>
                      <div class="buttons-set buttons-set--footer">
                        <bxs-btn
                        append-icon="arrow-right"
                        text>Invia Richiesta</bxs-btn>
                      </div>
                    </form>
                    <form action="" method="" v-if="activeLang == 'en'">
                      <div class="form-row"><input type="text" placeholder="Full Name" value=""></div>
                      <div class="form-row"><input type="email" placeholder="E-mail" value=""></div>
                      <div class="form-row"><input type="text" placeholder="Company" value=""></div>
                      <div class="form-row form-row--last"><textarea placeholder="Message"></textarea></div>
                      <div class="privacy-box-container">
                        <label>
                          <input type="checkbox"><small class="ml-3"> {{ $translate("By submitting this form, you acknowledge that you have read the privacy policy in accordance with Regulation (EU) 2016/679.", activeLang) }}</small>
                          <i>Requests submitted through this form will be handled within a maximum of 48 business hours of receipt.</i>
                        </label>
                      </div>
                      <div class="buttons-set buttons-set--footer">
                        <bxs-btn
                          append-icon="arrow-right"
                          class="button-white"
                          text>
                            Send Request
                        </bxs-btn>
                      </div>
                    </form>
                  </div>
                </bxs-col>
                <bxs-col
                cols="12"
                ds="6"
                >
                  <h2 class="contact-title text-center">{{ $translate('Come raggiungerci', activeLang) }}</h2>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5649.754834759561!2d9.019133278778124!3d45.711355511529085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47869a8e97f8ebc3%3A0xbea70262ab1da766!2sGammatom!5e0!3m2!1sit!2sit!4v1720505897289!5m2!1sit!2sit" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </bxs-col>
              </bxs-row>
            </div>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>
      <!--
        <bxs-col
        cols="12"
        ds="6"
        class="bxs-col-no-padding bxs-bg-col"
        >
      -->

      <plcd-driving-banner
        :title="page.plcd_driving_banner_page_title"
        :to="page.plcd_driving_banner_link"
        :src="page.plcd_driving_banner_image"
      >
      </plcd-driving-banner>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'contatti',
    computed: {
      ...mapState({
        language: state => state.language,
        pages(state) {
          const languagePages = {
              it: state.pages,
              en: state.pagesEn
          }
          return languagePages[this.language] || state.pages
          },
          page() {
            const objArray = Object.values(this.pages)
            return objArray.filter(pages => pages.template === 'template-contatti.php')[0]
          },
          activeLang() {
            return this.$store.state.language
          }
      })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins.scss';
.contact-title{
  letter-spacing: .006em;

  &.text-center{
    margin-bottom: 50px;
  }
}
.contact-content{
  letter-spacing: 0.006em;
}
.contact-second-section{
  margin-top: 100px;
}
.contact-page-form{
  max-width: 82%;

  i{
    font-size: 12px;
    margin: 0 0 20px;
    display: inline-block;
  }
}
</style>
