<template>
  <div class="page">
      <!-- HEADING 1 IMAGE BIG -->

      <plcd-page-heading
        title="Errore - 404 Pagina non trovata"
        :svgbg="true"
        subtitle="La pagina ricercata non esiste oppure è stata eliminata. Utilizza il menù per continuare la navigazione"
        :contentBig="true"
      >
      </plcd-page-heading>

  </div>
</template>

<script>
export default {
    name: 'err404'
}
</script>
<style scoped lang="scss">
  h1{
    font-size: 40px;
  }
</style>
