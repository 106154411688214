<template>
  <div class="big-menu" ref="bigMenu"
  v-if="is_MegaMenu"
  >
      <bxs-layout class="big-menu--content">
          <div ref="menuWrapper">
              <bxs-row>
                <bxs-col cols="9" ds="9" class="bxs-col-fullmobile">
                  <ul ref="menuContent" v-if="activeLang == 'it'">
                      <li class="firstLevel">
                        <router-link class="firstLevelLink" to="/about-us">About Us</router-link>  <span class="plus" ref="plus" @click="toggleSubMenu"></span>
                        <ul class="submenu">
                          <!--
                          <li>
                            <router-link to="/our-team-grid">Team</router-link>
                          </li>
                          -->
                          <li>
                            <router-link to="/su-misura">Su Misura</router-link>
                          </li>
                        </ul>
                      </li>
                      <li class="firstLevel">
                        <router-link class="firstLevelLink" to="/tecnologia">Tecnologia</router-link> <span class="plus" ref="plus" @click="toggleSubMenu"></span>
                        <ul class="submenu">
                          <li>
                            <router-link to="/metodologia-di-conservazione">Metodologia di conservazione</router-link>
                          </li>
                        </ul>
                      </li>
                      <li class="firstLevel">
                        <router-link class="firstLevelLink" to="/principi-attivi">Principi Attivi</router-link>
                      </li>
                      <li class="firstLevel">
                        <router-link class="firstLevelLink" to="/campi-di-applicazione">Applicazioni</router-link> <span class="plus" ref="plus" @click="toggleSubMenu"></span>
                        <ul class="submenu">
                          <li>
                            <router-link to="/polimeri">Polimeri</router-link>
                          </li>
                          <li>
                            <router-link to="/effetti-dei-raggi-gamma">Effetti dei raggi gamma</router-link>
                          </li>
                          <li>
                            <router-link to="/packaging">Packaging</router-link>
                          </li>
                          <li>
                            <router-link to="/differenza-metodi-di-sterilizzazione">Differenza metodi di sterilizzazione</router-link>
                          </li>
                        </ul>
                      </li>
                      <li class="firstLevel">
                        <router-link class="firstLevelLink" to="/press-releases">News</router-link> <span class="plus" ref="plus" @click="toggleSubMenu"></span>
                        <ul class="submenu">
                          <li>
                            <router-link to="/press">Press</router-link>
                          </li>
                          <li>
                            <router-link to="/media">Media</router-link>
                          </li>
                          <li>
                            <router-link to="/storytelling">Storytelling</router-link>
                          </li>
                        </ul>
                      </li>
                      <li class="firstLevel">
                        <router-link class="firstLevelLink" to="/certificazioni">Certificazioni</router-link>
                      </li>
                      <li class="firstLevel">
                        <router-link class="firstLevelLink" to="/contatti">Contatti</router-link> <span class="plus" ref="plus" @click="toggleSubMenu"></span>
                        <ul class="submenu">
                          <li>
                            <router-link to="/lavora-con-noi">Lavora con noi</router-link>
                          </li>
                        </ul>
                      </li>
                      <!--
                      <li
                      v-for="(item, i) in items"
                      :key="i">
                          <router-link
                          to="#"
                          exact
                          class="h3"
                          data-big-menu-item>
                              <span>{{ item }}</span>
                              <c-arrow />
                          </router-link>
                          -->
                          <!--
                            <router-link
                            :to="'/' + item.page.slug"
                            exact
                            class="h3"
                            data-big-menu-item>
                                <span>{{ item.title }}</span>
                                <c-arrow />
                            </router-link>
                          -->
                      <!--
                      </li>
                          -->
                  </ul>
                  <ul ref="menuContent" v-if="activeLang == 'en'">
                      <li class="firstLevel">
                        <router-link class="firstLevelLink" to="/en/about-us">About Us</router-link>  <span class="plus" ref="plus" @click="toggleSubMenu"></span>
                        <ul class="submenu">
                          <!--
                          <li>
                            <router-link to="/our-team-grid">Team</router-link>
                          </li>
                          -->
                          <li>
                            <router-link to="/en/custom-designed">Tailor Made</router-link>
                          </li>
                        </ul>
                      </li>
                      <li class="firstLevel">
                        <router-link class="firstLevelLink" to="/en/technology">Technology</router-link> <span class="plus" ref="plus" @click="toggleSubMenu"></span>
                        <ul class="submenu">
                          <li>
                            <router-link to="/en/preservation-methodology">Preservation Methodology</router-link>
                          </li>
                        </ul>
                      </li>
                      <li class="firstLevel">
                        <router-link class="firstLevelLink" to="/en/active-ingredients">Active Ingredients</router-link>
                      </li>
                      <li class="firstLevel">
                        <router-link class="firstLevelLink" to="/en/application-fields">Applications</router-link> <span class="plus" ref="plus" @click="toggleSubMenu"></span>
                        <ul class="submenu">
                          <li>
                            <router-link to="/en/polymers">Polymers</router-link>
                          </li>
                          <li>
                            <router-link to="/en/effects-of-gamma-rays">Effects of gamma rays</router-link>
                          </li>
                          <li>
                            <router-link to="/en/packaging">Packaging</router-link>
                          </li>
                          <li>
                            <router-link to="/en/difference-sterilization-methods">Difference sterilization methods</router-link>
                          </li>
                        </ul>
                      </li>
                      <li class="firstLevel">
                        <router-link class="firstLevelLink" to="/en/press-releases">News</router-link> <span class="plus" ref="plus" @click="toggleSubMenu"></span>
                        <ul class="submenu">
                          <li>
                            <router-link to="/en/press">Press</router-link>
                          </li>
                          <li>
                            <router-link to="/en/media">Media</router-link>
                          </li>
                          <li>
                            <router-link to="/en/storytelling">Storytelling</router-link>
                          </li>
                        </ul>
                      </li>
                      <li class="firstLevel">
                        <router-link class="firstLevelLink" to="/en/certifications">Certifications</router-link>
                      </li>
                      <li class="firstLevel">
                        <router-link class="firstLevelLink" to="/en/contacts">Contacts</router-link> <span class="plus" ref="plus" @click="toggleSubMenu"></span>
                        <ul class="submenu">
                          <li>
                            <router-link to="/en/careers">Careers</router-link>
                          </li>
                        </ul>
                      </li>
                      <!--
                      <li
                      v-for="(item, i) in items"
                      :key="i">
                          <router-link
                          to="#"
                          exact
                          class="h3"
                          data-big-menu-item>
                              <span>{{ item }}</span>
                              <c-arrow />
                          </router-link>
                          -->
                          <!--
                            <router-link
                            :to="'/' + item.page.slug"
                            exact
                            class="h3"
                            data-big-menu-item>
                                <span>{{ item.title }}</span>
                                <c-arrow />
                            </router-link>
                          -->
                      <!--
                      </li>
                          -->
                  </ul>
                </bxs-col>
                <bxs-col cols="3" ds="3" class="bxs-col-hide-mobile">
                  <ul class="side-links">
                    <li><a href="mailto:info@gammatom.it">Contacts</a></li>
                    <li><a href="https://www.facebook.com/Gammatom.it/" target="_blank">Facebook</a></li>
                    <li><a href="https://www.linkedin.com/company/gammatom-srl/" target="_blank">LinkedIn</a></li>
                    <li><a href="https://www.youtube.com/channel/UCsAXLRvM0bCHsfIfr3zZOuQ" target="_blank">YouTube</a></li>
                  </ul>
                </bxs-col>
              </bxs-row>
          </div>

          <div class="big-menu--content--footer">
              <bxs-row
              no-gutters
              align="center">

                  <bxs-col
                  cols="6">
                      <div
                      class="text-left copy-text">
                      © Copyright {{ year }} Gammatom Srl. All Right Reserved
                      </div>
                  </bxs-col>

                  <bxs-col cols="6">
                    <div class="language text-right">
                      <bxs-lang-selector></bxs-lang-selector>
                    </div>
                  </bxs-col>
              </bxs-row>
          </div>
      </bxs-layout>
  </div>
</template>

<script>
import { gsap } from 'gsap'

export default {
  name: 'plcd-mega-menu',

  data() {
    return {
      year: new Date().getFullYear(),
      dir: false
    }
  },

  methods: {
    toggleSubMenu(e) {
      const el = e.target
      const elParent = el.parentNode
      const elMenu = elParent.querySelector('.submenu')
      el.classList.toggle('active')
      elParent.classList.toggle('active')

      this.dir = el.classList.contains('active')
      // console.log(this.$refs.menuContent, 'MEGA')

      const tlStart = gsap.timeline()
      const tlEnd = gsap.timeline()
      const list = this.$refs.menuContent.querySelectorAll('.firstLevelLink')

      if (this.dir) {
        list.forEach((e) => {
          if (!e.classList.contains('active')) {
            e.parentNode.classList.add('hide')
          }
        })

        const menuHeight = elMenu.scrollHeight
        tlStart.to(elMenu, {
         height: `${menuHeight}px`
        })
      } else {
        list.forEach((e) => {
          e.parentNode.classList.remove('hide')
          e.parentNode.classList.remove('active')
        })

        tlEnd.to(elMenu, {
         height: '0'
        })
      }
      /*
      this.$refs.firstLevel.forEach((e) => {
        console.log(e, 'MEGA')
      })
      */
    }
  },

  computed: {
    is_MegaMenu() {
      return this.$store.getters.get_is_mega_menu
    },
    activeLang() {
      return this.$store.state.language
    }
  }
  /*
  computed: {
      ...mapState({
          items: state => state.menu
      })
  }
  */
}
</script>

<style lang="scss">
@import '@/assets/styles/mq.scss';
@import '@/assets/styles/mixins.scss';
$menuBlack: #222;

.big-menu{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding-top: 100px;
  background-color: var(--color-primary);
  background-color: #ffebe0;
  color: $menuBlack;
  z-index: var(--layer-drawer);

  .side-links{
    margin-left: auto;

    li{
      margin-bottom: 10px;
      text-align: right;
      margin-right: 25px;
      a{
        font-size: 20px;
        line-height: 26px;
      }
    }
  }

  &--content{
      position: relative;
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      padding-bottom: var(--grid-gutter);

      .submenu{

        li{
          margin-bottom: 5px;

          @include breakpoint(mobile) {
            margin-bottom: 3px;
            margin-top: 3px;
          }

          a{
            font-size: 22px;
            text-decoration: none !important;

            @include breakpoint(mobile) {
              font-size: 16px;
            }
          }
        }
      }

      .firstLevel{
        transition: all .3s ease;
      }

      .firstLevel.active{
        opacity: 1 !important;
      }

      .firstLevel.hide{
        opacity: 0.2;
      }

      div{
          overflow: hidden;

          > ul{
              > li{
                  overflow: hidden;
                  height: var(--big-menu-list-item-height);
                  &:last-child {
                      border-bottom: none;
                  }

                  ul {
                    height: 0;
                    margin: 0;
                    padding: 0;
                  }

                  > a {
                      position: relative;
                      font-size: 52px;
                      text-decoration: none;
                      display: inline-block;
                      color: $menuBlack;

                      @include breakpoint(mobile) {
                        font-size: 32px;
                      }

                      @media (hover: hover) {
                          &:hover {
                              color: $menuBlack;
                          }
                      }

                      &.router-link-active {
                          color: $menuBlack;
                      }
                  }
              }
          }
      }

      &--footer{
          @include MainRegular;
          height: var(--big-menu-footer-min-height);
          overflow: hidden;
          color: $menuBlack;
          font-size: 18px;
          padding-top: 20px;
          border-top: 1px solid $menuBlack;

          a{
            @include SideRegular;
            text-decoration: none;
            white-space: nowrap;
            color: $menuBlack;
          }

          > .bxs-row{
              height: 100%;
          }
      }
  }
  .copy-text{
    font-size: 14px;
    line-height: 20px;
  }

  .email-text{
    font-size: 16px;
    transform: translate(0, -3px);

    a{
      border-bottom: 1px solid $menuBlack;
    }
  }

  .language{
    font-size: 16px;

    .trigger span{
      margin-left: 15px;
    }
  }

  // Animation part

  .plus{
    position: relative;
    transition: all .3s ease;
    top: -9px;

    @include breakpoint(mobile) {
      top: 0;
    }

    &:after, &:before{
      content: "";
      opacity: 1;
      position: absolute;
      left: 25px;
      top: -4px;
      right: 0;
      bottom: 0;
      transition: all .3s ease;
      width: 4px;
      height: 30px;
      background-color: $menuBlack;
      display: block;

      @include breakpoint(mobile) {
        width: 2px;
        height: 20px;
      }
    }
    &:after{
      transform: rotate(90deg);
      transition: all .3s ease;
    }

    &.active{
      &:after{
        transform: rotate(-90deg);
      }
      &:before{
        opacity: 0;
      }
    }
  }

  .bxs-col-hide-mobile{
    @include breakpoint(mobile) {
      display: none;
    }
  }

  .bxs-col-fullmobile{
    @include breakpoint(mobile) {
      width: 100%;
      flex: 100%;
      max-width: 100%;
      margin-top: -40px;
    }
  }
}
</style>
