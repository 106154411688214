<template>
    <div class="home-page">

      <plcd-fluid-hero
        data-scroll
        data-scroll-repeat="true"
      />

      <!-- ------------------------------------------------------------------------------------------------------------
      ------------------------------------------------------------------------------------------------------------ -->
      <section class="telling-payoff" id="tellingPayoff">
        <bxs-layout class="bxs-layout-vertical-padded">
          <h2 class="telling-payoff__message">
            <plcd-text-extrovert
              :infinite=true
              :animation=0
            >
              <template v-slot:word_one>{{ $translate("Chiarezza.", activeLang) }}</template>
              <template v-slot:word_two>{{ $translate("Trasparenza.", activeLang) }}</template>
              <template v-slot:word_three>{{ $translate("Semplicità.", activeLang) }}</template>
            </plcd-text-extrovert><br>
            <span v-if="activeLang == 'it'">Gammatom riassume così il suo modo di comunicare l’impresa.
            Attività quotidiane, storie ed esperienze, crescita tecnologica e sfide delle nostre risorse umane sono racchiuse in queste pagine.
            <strong ref="progressMessage" class="progressMessage" data-scroll>Buona lettura.<span class="progressDecoration" ref="progressDecoration"></span></strong></span>
            <span v-if="activeLang == 'en'">Gammatom encapsulates its approach to corporate communication in these terms. Daily activities, stories and experiences, technological growth, and the challenges faced by our human resources are all contained within these pages.<br>
            <strong ref="progressMessage" class="progressMessage" data-scroll>Enjoy your reading.<span class="progressDecoration" ref="progressDecoration"></span></strong></span>
          </h2>
        </bxs-layout>
      </section>

      <!-- ------------------------------------------------------------------------------------------------------------
      ------------------------------------------------------------------------------------------------------------ -->
      <section class="video">
        <bxs-layout>
          <bxs-row>
            <bxs-col
            cols="6"
            ds="7"
            class="align-self-center bxs-col-visionpay"
            style="padding-top: 60px;"
            >
              <h1 class="">Vision</h1>
              <div class="telling-payoff__message telling-payoff__message--vertical" v-if="activeLang == 'it'">
                <p>
                Motivazione al lavoro, tecnologie all’avanguardia, innovazione continua, qualità certificata, eticità, impegno sociale e rispetto per l’ambiente. Questi i <strong>valori essenziali</strong> sui quali fonda ogni decisione e proiezione futura dal 1969, anno di fondazione, la Gammatom.</p>
                <p>
                Anni di crescita nelle tecnologie d’irraggiamento di raggi gamma destinati a sterilizzare e sanificare prodotti per vari settori produttivi, tra cui la farmaceutica. Una comunicazione capace di acquisire nel tempo visibilità sul mercato nazionale ma soprattutto internazionale e competere attraverso una qualità di rilievo.</p>
                <p><strong>Formazione, ricerca e attività in Team sono gli elementi trainanti il successo di una PMI italiana collocata nell’eccellenza.</strong></p>
                <div class="about-signature" style="display: none !important">
                  <div class="animated-signature" ref="signature" data-scroll>
                    <bxs-figure src="/img/gerardo-soldini-signature.jpg" width="300" height="80"></bxs-figure>
                  </div>
                  <div class="about-signature__text">
                      CEO OF GAMMATOM
                  </div>
                </div>
              </div>
              <div class="telling-payoff__message telling-payoff__message--vertical" v-if="activeLang == 'en'">
                <p>
                  Motivation to work, state of the art technology, continuous innovation, certified quality, ethical behaviour, social commitment and respect for the environment. These are the <strong>essential values</strong>  at the heart of every decision and future planning made since 1969, when Gammatom was founded.</p>
                <p>
                  Years of growth in gamma ray irradiation technologies for use in sterilising and sanitising products for various production sectors including the pharmaceutical industry. A communication capable of acquiring visibility on the national market over time, but above all, on the international market and bringing quality to the competition.</p>
                <p><strong>Training, research and Team work are the driving forces behind the success of an excellently positioned Italian SME.</strong></p>
                <div class="about-signature">
                  <div class="animated-signature" ref="signature" data-scroll>
                    <bxs-figure src="/img/gerardo-soldini-signature.jpg" width="300" height="80"></bxs-figure>
                  </div>
                  <div class="about-signature__text">
                      CEO OF GAMMATOM
                  </div>
                </div>
              </div>
              <div class="telling-payoff__message__button text-left">
                <bxs-btn
                  append-icon="arrow-right"
                  text
                  class="btn-triangle"
                  to="/about-us"
                  v-if="activeLang == 'it'"
                > Leggi tutto
                </bxs-btn>
                <bxs-btn
                  append-icon="arrow-right"
                  text
                  class="btn-triangle"
                  to="/en/about-us"
                  v-if="activeLang == 'en'"
                > Read more
                </bxs-btn>
              </div>
            </bxs-col>
            <bxs-col
            cols="6"
            ds="5">
              <video muted="" playsinline="" loop="" preload="auto" autoplay data-scroll data-scroll-speed="1" data-scroll-delay="1.5">
                <source src="https://www.gammatom.it/wp-content/uploads/2020/01/Gammatom-video-home-short.mp4" type="video/mp4">
                <!--<source src="https://uploads-ssl.webflow.com/627ac4734b003f2b793163c8/6290f140a2e57f4182cd60ea_Vidéo-teaser-loop-transcode.mp4" type="video/mp4">-->
              </video>
            </bxs-col>
          </bxs-row>
          <bxs-row>
            <bxs-col
            cols="12"
            ds="12">
            <h2 v-if="activeLang == 'it'" class="video__payoff animation-ready" data-scroll-class="animate" data-scroll>La vocazione di applicare la tecnologia dei <span class="video__payoff__focus">"Gamma"</span> in molteplici settori industriali e di ricerca.</h2>
            <h2 v-if="activeLang == 'en'" class="video__payoff animation-ready" data-scroll-class="animate" data-scroll>The vocation to apply<span class="video__payoff__focus">"Gamma"</span> technology in multiple industrail and research fields.</h2>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>

      <!-- ------------------------------------------------------------------------------------------------------------
      ------------------------------------------------------------------------------------------------------------ -->
      <section class="sub-hero" id="subHero">
        <div class="infinite" data-scroll data-scroll-direction="horizontal" data-scroll-speed="6" data-scroll-target="#subHero">
          <div class="infinite__block"
            v-for="n in 7"
            :key="n"
          >
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              width="97.000000pt" height="116.000000pt" viewBox="0 0 97.000000 116.000000"
              preserveAspectRatio="xMidYMid meet">

              <g transform="translate(0.000000,116.000000) scale(0.100000,-0.100000)"
              fill="#ff5f00" stroke="none">
              <path d="M689 1112 c-8 -11 -56 -70 -107 -131 l-94 -111 -100 125 -100 125
              -135 0 c-105 0 -133 -3 -127 -12 5 -7 79 -102 166 -211 l158 -197 -61 -82
              c-96 -129 -120 -187 -120 -291 -1 -176 139 -311 321 -311 59 0 85 5 124 23 64
              31 118 88 153 159 23 48 27 70 27 142 0 102 -15 142 -103 263 -34 46 -61 88
              -61 94 0 8 153 194 293 355 26 30 47 59 47 64 0 5 -60 11 -133 12 -123 3 -135
              2 -148 -16z m-149 -664 c54 -77 61 -108 34 -159 -18 -36 -73 -62 -112 -53 -34
              8 -79 49 -86 80 -4 13 0 39 8 58 13 31 93 146 102 146 2 0 26 -32 54 -72z"/>
              </g>
            </svg>
            <h3 class="sub-hero__title"><span class="focus">TAILOR MADE</span> GAMMA IRRADIATION</h3>
            <h3 class="sub-hero__title sub-hero__title--little">Italian Excellence</h3>
          </div>
        </div>
        <bxs-layout class="bxs-layout-vertical-padded">
          <bxs-row>
            <bxs-col
            cols="12"
            ds="5">
              <bxs-figure
              src="https://gammatom.sviluppo-web.net/wp-content/uploads/2025/03/gammatom-safeness-certified-logo.png"
              data-scroll
              data-scroll-offset="30%"
              >
              </bxs-figure>
            </bxs-col>
            <bxs-col
            cols="12"
            ds="7"
            align-self="center"
            class="text-center"
            >
              <h1 class="title-about">{{ $translate("Valori Sostenibili", activeLang) }}</h1>
              <h2 class="telling-payoff__message telling-payoff__message--vertical rtl" v-if="activeLang == 'it'">Quando si parla di radiazioni, le persone irrigidiscono il loro pensiero, accostandolo a pura diffidenza.<br>
                È però la "non conoscenza" a determinare questo tipo di reazioni.<br>
                I nostri processi sono garantiti e non hanno alcun impatto e ricaduta nell'ambiente del territorio in cui crescono anche i nostri figli. Il valore di un processo produttivo sostenibile è riconosciuto nella certificazione “International Sustainability & Carbon Certification” acquisita nel 2024.
              </h2>
              <h2 class="telling-payoff__message telling-payoff__message--vertical rtl" v-if="activeLang == 'en'">When it comes to radiation, people tend to stiffen their thinking, associating it with sheer distrust. However, it is "lack of knowledge" that triggers such reactions. We have opted for educational communication with the aim to "inform" producers and consumers about the effectiveness and necessity of sterilization through gamma ray irradiation.<br>"Well Being - Safety and Quality Life" is the slogan that defines our new customer communication campaigns, a clear way to convey our values. <br><br>
              Our processes are guaranteed and have no impact or fallout on the environment of the area where our children also grow. The value of a sustainable production process is recognized in the "International Sustainability & Carbon Certification" acquired in 2024.
              </h2>
              <!--
              <div class="telling-payoff__message__button">
                <bxs-btn
                  append-icon="arrow-right"
                  text
                  class="btn-triangle"
                  to="/about-us"
                > About Us
                </bxs-btn>
              </div>
              -->
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>

      <!-- ------------------------------------------------------------------------------------------------------------
      ------------------------------------------------------------------------------------------------------------ -->
      <section
      class="home-swiper home-applications animation-ready showing"
      data-scroll
      data-scroll-offset="25%">
        <bxs-layout>
          <bxs-row>
            <bxs-col
            cols="12"
            ds="12"
            data-scroll>
              <h2>{{ $translate("Campi di applicazione", activeLang) }}</h2>
            </bxs-col>
          </bxs-row>

          <bxs-row>
            <bxs-col cols="8" ds="8">
                <swiper
                  :slides-per-view="5"
                  :space-between="100"
                  :modules="modules"
                  :autoplay="{
                    delay: 2500,
                    disableOnInteraction: false,
                  }"
                  :breakpoints="{
                    '0': {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    '520': {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    '768': {
                      slidesPerView: 4,
                      spaceBetween: 40,
                    },
                    '1024': {
                      slidesPerView: 5,
                      spaceBetween: 40,
                    }
                  }"
                  @swiper="onSwiper"
                  @slideChange="onSlideChange"
                >
                  <swiper-slide>
                    <bxs-figure src="/img/applicazioni/ospedaliero.png"></bxs-figure>
                    <div class="swiper-title">{{ $translate("Ospedaliero ", activeLang) }}</div>
                  </swiper-slide>
                  <swiper-slide>
                    <bxs-figure src="/img/applicazioni/packaging.png"></bxs-figure>
                    <div class="swiper-title">Packaging</div>
                  </swiper-slide>
                  <swiper-slide>
                    <bxs-figure src="/img/applicazioni/tessuti-umani-2.png"></bxs-figure>
                    <div class="swiper-title">{{ $translate("Tessuti Umani", activeLang) }}</div>
                  </swiper-slide>
                  <swiper-slide>
                    <bxs-figure src="/img/applicazioni/impianti-dentali.png"></bxs-figure>
                    <div class="swiper-title">{{ $translate("Impianti Dentali", activeLang) }}</div>
                  </swiper-slide>
                  <swiper-slide>
                    <bxs-figure src="/img/applicazioni/medico.png"></bxs-figure>
                    <div class="swiper-title">{{ $translate("Medico ", activeLang) }}</div>
                  </swiper-slide>
                  <swiper-slide>
                    <bxs-figure src="/img/applicazioni/veterinario.png"></bxs-figure>
                    <div class="swiper-title">{{ $translate("Veterinario ", activeLang) }}</div>
                  </swiper-slide>
                  <swiper-slide>
                    <bxs-figure src="/img/applicazioni/farmaceutico.png"></bxs-figure>
                    <div class="swiper-title">{{ $translate("Farmaceutico ", activeLang) }}</div>
                  </swiper-slide>
                  <swiper-slide>
                    <bxs-figure src="/img/applicazioni/aerospaziale.png"></bxs-figure>
                    <div class="swiper-title">{{ $translate("Aerospaziale ", activeLang) }}</div>
                  </swiper-slide>
                  <swiper-slide>
                    <bxs-figure src="/img/applicazioni/cosmetico.png"></bxs-figure>
                    <div class="swiper-title">{{ $translate("Cosmetico ", activeLang) }}</div>
                  </swiper-slide>
                </swiper>
            </bxs-col>
          </bxs-row>

          <bxs-row>
            <bxs-col cols="12" ds="12">
              <div class="text-center">
                <bxs-btn
                append-icon="arrow-right"
                text
                class="btn-triangle"
                v-if="activeLang == 'it'"
                to="/campi-di-applicazione">{{ $translate("I campi di applicazione", activeLang) }}</bxs-btn>
                <bxs-btn
                append-icon="arrow-right"
                text
                class="btn-triangle"
                v-if="activeLang == 'en'"
                to="/en/application-fields">{{ $translate("I campi di applicazione", activeLang) }}</bxs-btn>
              </div>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>

      <!-- ------------------------------------------------------------------------------------------------------------
      ------------------------------------------------------------------------------------------------------------ -->
      <!--
      <section
      class="news-wrapper animation-ready showing"
      data-scroll
      data-scroll-offset="40%">
        <div class="orange-shadow"></div>
        <bxs-layout class="bxs-layout-vertical-padded">
          <h2>Latest News & Events</h2>

          <bxs-row>
            <bxs-col cols="12" ds="4">
              <router-link
              to="/news"
              class="news">
                <bxs-figure class="news--figure" src="/img/news1.png" ratio="16/9">
                </bxs-figure>
                <div class="news--content">
                    <h3 class="news--content--title">Collaborazioni prestigiose: Politecnico Torino</h3>
                    <div class="news--content--paragraph">
                      Le sinergie con partners istituzionali nell'ambito della ricerca scientifica,
                      sono un elemento determinante per la conferma della capacità e validità dei nostri servizi.
                      In questo caso citiamo con orgoglio una collaborazione con il Politecnico di Torino.
                    </div>
                    <bxs-btn
                    append-icon="arrow-right"
                    text>{{ $translate("Approfondisci", activeLang) }}</bxs-btn>
                </div>
              </router-link>
            </bxs-col>

            <bxs-col cols="12" ds="4">
              <router-link
                to="/news"
                class="news"
                >
                <bxs-figure class="news--figure" src="/img/news2.png" ratio="16/9">
                </bxs-figure>
                <div class="news--content">
                    <h3 class="news--content--title">Collaborazioni prestigiose: Politecnico Torino</h3>
                    <div class="news--content--paragraph">
                      Le sinergie con partners istituzionali nell'ambito della ricerca scientifica,
                      sono un elemento determinante per la conferma della capacità e validità dei nostri servizi.
                      In questo caso citiamo con orgoglio una collaborazione con il Politecnico di Torino.
                    </div>
                    <bxs-btn
                    append-icon="arrow-right"
                    text>{{ $translate("Approfondisci", activeLang) }}</bxs-btn>
                </div>
              </router-link>
            </bxs-col>

            <bxs-col cols="12" ds="4">
              <router-link
                to="/news"
                class="news"
                >
                <bxs-figure class="news--figure" src="/img/news3.png" ratio="16/9">
                </bxs-figure>
                <div class="news--content">
                    <h3 class="news--content--title">Collaborazioni prestigiose: Politecnico Torino</h3>
                    <div class="news--content--paragraph">
                      Le sinergie con partners istituzionali nell'ambito della ricerca scientifica,
                      sono un elemento determinante per la conferma della capacità e validità dei nostri servizi.
                      In questo caso citiamo con orgoglio una collaborazione con il Politecnico di Torino.
                    </div>
                    <bxs-btn
                    append-icon="arrow-right"
                    text>{{ $translate("Approfondisci", activeLang) }}</bxs-btn>
                </div>
              </router-link>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>
      -->

      <!-- ------------------------------------------------------------------------------------------------------------
      ------------------------------------------------------------------------------------------------------------ -->
      <section
      class="news-swiper"
      data-scroll>
        <div class="orange-shadow"></div>
        <bxs-layout class="bxs-layout-vertical-padded">
          <h2>Latest News & Events</h2>

          <bxs-row>
            <bxs-col cols="12" ds="12">
              <bxs-latest-news/>
              </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>

      <!-- ------------------------------------------------------------------------------------------------------------
      ------------------------------------------------------------------------------------------------------------ -->
      <section
      class="home-swiper home-certifications"
      data-scroll>
        <bxs-layout>
          <bxs-row>
            <bxs-col
            cols="12"
            ds="12"
            data-scroll>
              <h2>{{ $translate("Certificazioni ", activeLang) }}</h2>
              <!--
              <h2
              data-anim-scroll="fade-in"
              data-anim-scroll-delay="2"
              data-anim-scroll-duration="3">Certificazioni</h2>
              <h2
              data-anim-scroll="ciao"
              data-anim-scroll-delay="5"
              data-anim-scroll-duration="3">Certificazioni</h2>
              -->
            </bxs-col>
          </bxs-row>

          <bxs-row>
            <bxs-col cols="8" ds="8">
                <swiper
                :slides-per-view="4"
                :space-between="200"
                :modules="modules"
                :autoplay="{
                  delay: 2500,
                  disableOnInteraction: false
                }"
                :breakpoints="{
                  '0': {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  '520': {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  '768': {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  '1024': {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  }
                }"
                @swiper="onSwiper"
                @slideChange="onSlideChange">
                  <swiper-slide>
                    <bxs-figure src="/img/certificazioni/logo-aifa.png"></bxs-figure>
                    <div class="swiper-title">AIFA</div>
                  </swiper-slide>
                  <swiper-slide>
                    <bxs-figure src="/img/certificazioni/logo-ministry-of-health-japan.png"></bxs-figure>
                    <div class="swiper-title">MINISTRY OF HEALTH JAPAN</div>
                  </swiper-slide>
                  <swiper-slide>
                    <bxs-figure src="/img/certificazioni/logo-dnv-gl.png"></bxs-figure>
                    <div class="swiper-title">DNV-GL</div>
                  </swiper-slide>
                  <swiper-slide>
                    <bxs-figure src="/img/certificazioni/logo-ministero-della-salute-italia.png"></bxs-figure>
                    <div class="swiper-title">MINISTERO DELLA SALUTE</div>
                  </swiper-slide>
                </swiper>
            </bxs-col>
          </bxs-row>

          <bxs-row>
            <bxs-col cols="12" ds="12">
              <div class="text-center">
                <bxs-btn
                append-icon="arrow-right"
                text
                class="btn-triangle"
                v-if="activeLang == 'it'"
                to="/certificazioni">Le nostre certificazioni</bxs-btn>
                <bxs-btn
                append-icon="arrow-right"
                text
                class="btn-triangle"
                v-if="activeLang == 'en'"
                to="/en/certifications">Our Certifications</bxs-btn>
              </div>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>
    </div>
</template>

<script>
import { gsap } from 'gsap'

import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, EffectFade, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'
import { mapState } from 'vuex'

export default {
    name: 'home',
    data () {
      return {
        heroActive: 0
      }
    },
    components: {
      Swiper,
      SwiperSlide
    },
    setup () {
      const onSwiper = (swiper) => {
        // console.log(swiper)
      }
      const onSlideChange = () => {
        // console.log('slide change')
      }
      return {
        onSwiper,
        onSlideChange,
        modules: [Autoplay],
        modulesNews: [EffectFade, Navigation]
      }
    },
    mounted () {
      // Mutation Observer for Locomotive
      this.observer = new MutationObserver(mutations => {
        for (const m of mutations) {
          const newValue = m.target.getAttribute(m.attributeName)

          this.$nextTick(() => {
            this.onClassChange(newValue, m.oldValue)
          })
        }
      })

      this.observer.observe(this.$refs.progressMessage, {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ['class']
      })
    },
    computed: {
        ...mapState({
          language: state => state.language,
          pages(state) {
            const languagePages = {
                it: state.pages,
                en: state.pagesEn
            }
            return languagePages[this.language] || state.pages
            },
            page() {
              const objArray = Object.values(this.pages)
              return objArray.filter(pages => pages.template === 'template-home.php')[0]
            }
        }),
        activeLang() {
          return this.$store.state.language
        }
    },

    methods: {
      onClassChange(classAttrs) {
          const classList = classAttrs.split(' ')

          if (classList.includes('is-inview')) {
            this.animateWord(this.$refs.progressMessage, this.$refs.progressDecoration)
            this.observer.disconnect()
          }
        },
      animateWord (word, decoration) {
        const wordMotion = gsap.timeline({})

        wordMotion.delay(3)

        wordMotion
          .fromTo(word, {
            clipPath: 'inset(0 100% 0 0)',
            webkitClipPath: 'inset(0 100% 0 0)'
          }, {
            clipPath: 'inset(0 0% 0 0)',
            webkitClipPath: 'inset(0 0% 0 0)',
            duration: 0.7,
            ease: 'power3.easeOut'
          })
          .fromTo(decoration, {
            clipPath: 'inset(0 0 0 100%)',
            webkitClipPath: 'inset(0 0 0 100%)'
          }, {
            clipPath: 'inset(0 0 0 0%)',
            opacity: 1,
            webkitClipPath: 'inset(0 0 0 0%)',
            duration: 0.3,
            ease: 'power4'
          })
      }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

/* Home */
.home-page{
  margin-top: -90px;
}

// Decoration and Message

.progressMessage{
  position: relative;

  @include breakpoint(desktop) {
    display: block;
    max-width: 260px;
  }
}

.progressDecoration{
  position: absolute;
  background: black;
  width: 102%;
  height: 5px;
  left: 0;
  bottom: 0;
  opacity: 0;

  @include breakpoint(tablet_portrait) {
    width: 80%;
  }
}

/* News Element */
.news-wrapper{
  h2{
    font-size: 36px;
  }

  h3{
    font-size: 25px;
    letter-spacing: .005em;
  }

  .bxs-layout{
    @include breakpoint(tablet_portrait) {
      margin-top: 30px !important;
      margin-bottom: 30px !important;
    }
  }
}
.news{
  border: 1px solid rgba(19, 26, 34, .1);
  padding: 10px 10px 20px;
  // cursor: pointer !important;
  // justify-content: stretch;

  figure > div{
    transition: all .2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transform-origin: center center;
  }
/*
  &:hover{
    .news--content--title{
        color: var(--color-primary);
    }

    figure > div{
      transform: scale(1.03);
    }
  }
*/

  &--content{
    padding: 40px 10px 0;
    // align-self: stretch;

    &--title{
      margin-bottom: 20px;
      /*-moz-animation-delay: ;
      &.hovered > &{
        color: var(--color-primary);
      }
      */
    }

    &--paragraph{
      color:#131a22;
      opacity: .6;
      font-size: 15px;
      text-align: justify;
      @include SideRegular;
      margin-bottom: 40px;
    }

    &--category{
      @include MainRegular;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;

      > div{
        border-radius: 5px;
        padding: 8px 12px 5px;
        text-transform: uppercase;
        border: 1px solid #ccc;
        letter-spacing: 0.02em;
        color: #666;
        font-size: 13px;
      }
    }
  }

  &:hover{
    text-decoration: none;

    > .news--figure{
      img{
          // transition: all .3s ease-in-out;
          // transform: scale(1.02);
      }
    }
  }
}

.news-swiper{
  .bxs-col-12 {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 15px;
    max-width: 98%;
    margin: 0 18px;
  }

  .news--content--paragraph{
    height: 58px;
    overflow: hidden;
  }

  .news--content{
    padding-right: 10% !important;
  }

  .news--content--title{
    @include MainRegular;

    a{
      @include MainRegular;
    }
  }

  .news{
    display: flex;
    border: none;
    padding: 0 !important;

    a{
      text-decoration: none !important;
    }

    .figure{
      width: 32%;
      border-radius: 3px;
    }

    .news--content{
      width: 58%;
      padding: 50px;
    }

    .news--content--title{
      transition: all .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      text-decoration: none !important;
    }

    .news--content--title:hover{
        color: var(--color-primary);
    }
  }
}

.flex-center-vertical{
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  flex-direction: column;
}

.home-applications{
  .bxs-layout{
    border-top: 2px solid var(--color-primary);
    border-bottom: 3px solid var(--color-primary);
  }
}

.title-about{
  padding-left: 20px;
}

.telling-payoff{
  background: url('@/assets/svg/gammatom-endless-bg.svg');
  padding-top: 20px;
  padding-bottom: 40px;
  background-size: 1%;

  .bxs-layout{
    @include breakpoint(app_mobile) {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  br{
    @include breakpoint(tablet_portrait) {
      display: none;
    }
  }

  &__message{
    letter-spacing: 0.03em;
    font-size: 30px;

    &__button{
      text-align: center;
      margin-top: 40px;
    }

    &--vertical{
      @include SideRegular;
      line-height: 23px;
      // padding: 0 90px 20px;
      // text-align: center;
      padding-right: 20px;
      color: #131a22;
      opacity: .6;
      font-size: 16px;
      margin-top: 20px;

      p{
        margin-bottom: 20px;
      }

      &.rtl{
        padding-left: 20px;
      }

      @include breakpoint(fullscreen) {
        padding-right: 0;
        padding-left: 0;
      }

      @include breakpoint(app_mobile) {
        text-align: left;
        line-height: 30px;
        font-size: 22px;
      }

      br{
        @include breakpoint(app_mobile) {
          display: none !important;
        }
      }
    }

    .text-extrovert{
      font-size: 68px;
      line-height: 110px;
      display: block;
      height: 110px;

      @include breakpoint(tablet_portrait) {
        font-size: 62px;
      }

      @include breakpoint(mobile) {
        font-size: 57px;
      }

      .content__text{
        &:nth-child(2), &:nth-child(3){
          margin-top: -110px;
        }
      }
    }

    > span{
      line-height: 40px;

      @include breakpoint(tablet_portrait) {
        line-height: 48px;
      }
    }
  }
}

.telling-payoff__message__button.text-left{
  text-align: left;
}

.video{
  background-color: rgba(230, 230, 230, .65);

  &__payoff{
    opacity: 0;
    filter: blur(180px);
    text-align: center;
    margin: 32px 0 52px;
    font-size: 44px;

    @include breakpoint(desktop) {
      margin: 22px 0 42px;
      font-size: 42px;
    }

    @include breakpoint(tablet_portrait) {
      margin: 0;
      font-size: 32px;
    }

    &.animate{
      opacity: 1;
      filter: blur(0px);
    }

    &__focus{
      color: var(--color-primary);
    }
  }
}

.orange-shadow{
  width: 120em;
  height: 120em;
  opacity: 1;
  filter: blur(280px);
  -webkit-filter: blur(280px);
  background-color: var(--color-primary);
  border-radius: 60em;
  position: absolute;
  top: auto;
  bottom: -222%;
  left: auto;
  right: -87%;
  transform: rotate(34deg)scale3d(1, .6, 1);
  opacity: 0.5;
  will-change: filter;

  @include breakpoint(fullscreen) {
    filter: blur(90px);
    -webkit-filter: blur(90px);
    opacity: 0.2;
  }

  @include breakpoint(tablet_landscape) {
    display: none;
  }
}

.home-swiper{
  h2{
    font-size: 36px;

    @include breakpoint(mobile) {
      font-size: 32px;
    }
  }
  .bxs-layout{
    // padding-top: var(--distance-vertical-d);
    // padding-bottom: var(--distance-vertical-d);

    padding-top: 50px;
    padding-bottom: 50px;

    @include breakpoint(tablet_portrait) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  .figure{
    background-color: transparent !important;

    @include breakpoint(mobile) {
      padding: 0 130px;
    }
  }

  .swiper-slide .slide-wrapper{
    border: 1px solid rgba(19, 26, 34, 0.1);
    padding: 40px;
  }

  .swiper-title{
    margin: 30px 0;
    text-align: center;
    font-size: 18px;
    opacity: .7;
  }
  .bxs-row{
    > div{
      margin: 0 auto !important;
    }
  }
}

.sub-hero{
  position: relative;
  overflow: hidden;
  padding-top: 80px;

  @include breakpoint(app_mobile) {
    padding-top: 0;
  }

  .bxs-layout{
    padding-bottom: 50px;
    margin-bottom: 0 !important;
    margin-top: 50px !important;

    @include breakpoint(app_mobile) {
      margin-top: 50px !important;
      padding-bottom: 0 !important;
    }
  }

  &__title{
    margin: 0 20px;

    .focus{
      color: var(--color-primary)
    }
  }

  .infinite{
    display: flex;

    @include breakpoint(app_mobile) {
      display: none !important;
    }

    &__block{
      $offset: 800px;
      display: flex;
      white-space: nowrap;
      margin-left: -$offset;
      padding-right: 800px;

      h3{
        font-size: 23px !important;
        letter-spacing: .04em !important;
      }

      svg{
        width: 30px;
        height: 30px;
        margin-top: -6px;
      }
    }
  }
}

.home-certifications{
  background: var(--color-secondary);
}

/* SWIPER Overwrite */

.swiper-fade{
  .swiper-slide{
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-duration: .7s !important;
    z-index: -1;
  }
  .swiper-slide.swiper-slide-active{
    z-index: 1;
  }
}

/* Custom Navigation */

.swiper-horizontal.swiper-fade{
  overflow: visible;
}
.swiper-custom-navigation{
  position: absolute;
  width: calc(10% + 15px);
  height: calc(100% + 30px);
  top: -15px;
  right: -15px;

  &:after{
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    display: block;
    background: #ddd;
    left: 0;
    top: 0;
    margin-left: 1px;
  }

  &:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    display: block;
    background: #ddd;
    top: 50%;
    margin-top: -1px;
    left: 0;
  }
}
.swiper-button-prev{
  left: auto;
  right: 3.8%;
  top: 24%;
}
.swiper-button-next, .swiper-button-prev{
  transition: opacity .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.swiper-button-next:hover, .swiper-button-prev:hover{
  opacity: .4;
}
.swiper-button-next{
  left: auto;
  right: 3.8%;
  bottom: 17%;
  top: auto;
}

</style>
