<template>
    <div
    ref="wrapper"
    id="wrapper"
    data-scroll-container
    :class="[
        'app--wrapper',
        'appLang-'+activeLang,
        scroll_direction ? 'app-scroll-direction-' + scroll_direction : '',
        is_mega_menu ? 'app-megamenu-open' : '',
        {
            'app-loading': is_in_transition || is_loading,
            'app-noevt': is_in_transition || is_loading
        }
    ]">
        <preloader
        v-if="!is_preloaded"
        ref="preloader"
        :resources="resources"
        @complete="preloadComplete">
            <logo max-width="200px" />
        </preloader>

        <!-- CHECK TRANSITION NAVBAR -->
        <bxs-navbar
        v-if="is_preloaded && !is_in_transition"
        ref="navframe"
        :class="hideNav ? 'hideNav': '' "
        />

        <!-- view -->
        <main
        ref="main"
        class="app--main">
            <router-view
            v-slot="{ Component }"
            class="app--page">
                <transition
                @enter="enterTransition"
                @leave="leaveTransition"
                mode="out-in"
                appear>
                    <component
                    v-if="is_preloaded"
                    ref="page"
                    id="page"
                    :is="Component"
                    :key="$route.name" />
                </transition>
            </router-view>

            <transition
            name="fade"
            mode="out-in">
                <bxs-loader
                v-if="is_preloaded && is_loading"
                fullscreen />
            </transition>
        </main>
<!--
        <plcd-cursor-filter
        v-if="is_preloaded"
        ref="cursorfilter"
         />
-->
        <bxs-footer
        v-if="is_preloaded"
        ref="footer" />

        <!-- big menu -->
        <plcd-mega-menu />

        <bxs-coookies
        v-if="is_preloaded"
        buttonText="Accept"
        storageName="https://gammatom.it:cookie:accepted">
            <template #default="{ accept }">
                <div class="flex-1 text-ui">
                    <div class="mb-8">
                        <small class="text-600">Notice!</small>
                    </div>

                    <div class="mb-ui">
                        <p>Questo sito non utilizza cookie di profilazione. Si fa invece ricorso a cookie tecnici, anche di terze parti, al fine di migliorare l'esperienza di navigazione. <br> Cliccando sul tasto “Rifiuta”, chiuderai il banner senza prestare il consenso ad alcun cookie, ad eccezione di quelli necessari.</p>
                    </div>

                    <div class="text-right">
                        <bxs-btn
                        pill
                        min-width="80"
                        class="mr-4"
                        @click="accept">Accetta</bxs-btn>

                        <bxs-btn
                        pill
                        min-width="80"
                        class="mr-4"
                        @click="accept">Rifiuta</bxs-btn>

                        <!--
                        <bxs-menu title="Impostazioni cookie">
                            <template #activator="{ on }">
                                <bxs-btn
                                v-on="on"
                                pill
                                outlined
                                min-width="80"
                                @click="cookie.on = true">Impostazioni</bxs-btn>
                            </template>

                            <div>
                                <div class="pa-ui">
                                    <logo max-width="80" />
                                </div>

                                <div class="bxs-divider"></div>

                                <div class="pa-ui">
                                    <p class="text-ui text-500 h6">Cookie strettamente necessari</p>
                                    <p>I cookie strettamente necessari dovrebbero essere sempre attivati per poter salvare le tue preferenze per le impostazioni dei cookie.</p>

                                    <bxs-switch-field
                                    label="Attivato"
                                    :model-value="true"
                                    disabled
                                    class="mt-ui" />
                                </div>

                                <div class="bxs-divider"></div>

                                <div class="pa-ui">
                                    <p class="text-ui text-500 h6">Cookie di terze parti</p>
                                    <p>Google Maps è un servizio di visualizzazione di mappe gestito da Google che permette a questo sito di indicare il luogo dove si trova l'azienda.</p>

                                    <bxs-switch-field
                                    label="Disattivato"
                                    class="mt-ui" />
                                </div>

                                <div class="bxs-divider"></div>
                            </div>

                            <template #actions>
                                <div class="px-ui">
                                    <bxs-btn
                                    pill
                                    light
                                    min-width="80"
                                    @click="accept">Salva le modifiche</bxs-btn>
                                </div>
                            </template>
                        </bxs-menu>
                        -->
                    </div>
                </div>
            </template>
        </bxs-coookies>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { fixVh } from '@/assets/libs/utils'
import transitions from '@/assets/libs/transitions'
import animations from '@/assets/libs/animations'

import LocoScroll from 'locomotive-scroll'
import Cookie from '@/components/Cookie.vue'
import CursorFilter from '@/components/CursorFilter.vue'
import Footer from '@/components/layout/Footer.vue'
import Preloader from '@/components/Preloader.vue'
import Navbar from '@/components/layout/Navbar.vue'

export default {
    name: 'App',
    components: {
        preloader: Preloader,
        'bxs-navbar': Navbar,
        'bxs-footer': Footer,
        'bxs-coookies': Cookie,
        'plcd-cursor-filter': CursorFilter
    },
    computed: {
        ...mapState({
            resources: state => state.resources,
            is_loading: (state) => state.is_loading,
            is_preloaded: (state) => state.is_preloaded,
            is_in_transition: state => state.is_in_transition,
            is_mega_menu: state => state.is_mega_menu
        }),
        ...mapGetters([
          'getCurrentPage'
        ]),
        activeLang() {
          return this.$store.state.language
        },
        homePage() {
          if (this.$route.path === '/' || this.$route.path === '/home') {
            return true
          } else {
            return false
          }
        }
    },
    head () {
      const page = this.getCurrentPage()
      const has_dynamic_seo = !!page && !!page.seo
      // const has_robots = has_dynamic_seo && !!this.page.seo.data.robots
      const has_og_img = has_dynamic_seo && page.seo.data && page.seo.data.og_image && page.seo.data.og_image.length > 0

      return {
        title: has_dynamic_seo ? page.seo.data.title : '',
        meta: [
          { name: 'description', content: has_dynamic_seo ? page.seo.data.description : '' },
          { name: 'article:modified_time', content: has_dynamic_seo ? page.seo.data.article_modified_time : '' },
          // og
          { name: 'og:type', content: has_dynamic_seo ? page.seo.data.og_type : '' },
          { name: 'og:site_name', content: has_dynamic_seo ? page.seo.data.title : '' },
          { name: 'og:title', content: has_dynamic_seo ? page.seo.data.og_description : '' },
          { name: 'og:description', content: has_dynamic_seo ? page.seo.data.title : '' },
          { name: 'og:image', content: has_og_img ? page.seo.data.og_image[0].url : '' },
          { name: 'og:url', content: window.location.href },
          // twc
          { name: 'twitter:card', content: has_dynamic_seo ? page.seo.data.twitter_card : '' },
          { name: 'twitter:title', content: has_dynamic_seo ? page.seo.data.og_description : '' },
          { name: 'twitter:description', content: has_dynamic_seo ? page.seo.data.title : '' },
          { name: 'twitter:image', content: has_og_img ? page.seo.data.og_image[0].url : '' },
          // indexing
          { name: 'robots', content: has_dynamic_seo ? `${page.seo.data.robots.index},${page.seo.data.robots.follow}` : '' }
        ],
        script: [
          { type: 'application/ld+json', content: has_dynamic_seo ? JSON.stringify(page.seo.data.schema) : '' }
        ]
      }
    },
    data () {
        return {
            cookie: {
                on: false
            },
            scroll: null,
            scroll_direction: null,
            anims_els: [],
            hideNav: false,
            stopResizeFix: false
        }
    },
    mounted () {
        this.$nextTick(this.start)
        this.resize()
    },
    watch: {
        is_in_transition (newVal) {
            if (newVal) this.pause()
            else this.play()
        }
    },
    methods: {
        start () {
            console.log('app start()')

            this.resize()
            window.addEventListener('resize', this.resize)
            this.$refs.preloader.start()
        },
        preloadComplete () {
            console.log('app preloadComplete()')

            this.$store.commit('set_is_preloaded', true)
            this.$store.commit('set_is_loading', false)
        },
        play () {
            this.$nextTick(() => {
                console.log('app play()', this.$refs)

                // Note: scroll-sections are optional but recommended to improve performance, particularly in long pages.
                const sections = [this.$refs.footer.$el, ...this.$refs.page.$el.querySelectorAll('section')]
                if (sections.length > 0) {
                    sections.forEach((el) => {
                        if (!el.hasAttribute('data-scroll-section')) el.setAttribute('data-scroll-section', '')
                    })
                }

                if (!this.scroll) {
                    this.scroll = new LocoScroll({
                        el: this.$refs.wrapper,
                        smooth: true,
                        initPosition: { x: 0, y: 0 },
                        direction: 'vertical',
                        gestureDirection: 'vertical',
                        lerp: 0.1,
                        getDirection: true,
                        getSpeed: false,
                        multiplier: 0.8,
                        firefoxMultiplier: 25,
                        touchMultiplier: 4,
                        scrollFromAnywhere: false,
                        tablet: { direction: 'vertical', smooth: true },
                        smartphone: { direction: 'vertical', smooth: true }
                    })
                }

                this.resize()

                this.scroll.on('scroll', this.handleScroll)

                this.scroll.on('call', (val) => {
                    console.log('loco call', val)
                    this.$emitter.emit('scroll-call', val)
                })

                const blanks = [...document.querySelectorAll(['a[target="_blank"]'])]
                blanks.forEach((el) => {
                    if (!el.hasAttribute('rel')) el.setAttribute('rel', 'noopener')
                })

                // ||
                if (this.anims_els.length > 0) {
                    const observer = new IntersectionObserver(obsCb, {
                        rootMargin: '0px',
                        threshold: 0.5
                    })

                    function obsCb(entries, obs) {
                        entries.forEach((entry) => {
                            if (entry.isIntersecting) {
                                const el = entry.target
                                const anim = entry.target.dataset.animScroll
                                const opts = {
                                    delay: el.dataset.animScrollDelay,
                                    duration: el.dataset.animScrollDuration
                                }

                                animations.get(anim)(el, opts)
                            }
                        })
                    }

                    this.anims_els.forEach((el) => observer.observe(el))
                }
            })
        },
        async getPage () {
            const pgs = ['work', 'service', 'article_post']
            console.log('getPage() ...', this.$store.state.page_enter)

            if (this.$route.meta.is_page && this.$store.state.page_enter !== this.$store.state.page_leave) {
                const page = await this.$store.dispatch('getPage', this.$route.path === '/' ? 'home' : this.$route.path)
                if (!page) throw new Error('route.not_found')
            }

            if (pgs.includes(this.$route.name)) {
                const subpage = await this.$store.dispatch(`get_${this.$route.name}`, this.$route.params.slug)
                if (!subpage) throw new Error('route.not_found')
            }

            return true
        },
        pause () {
            console.log('app pause()')

            if (this.scroll) this.scroll.stop()
        },
        // handlers
        handleScroll (evt) {
            this.scroll_direction = evt.direction
            // console.log(evt.delta.y)
            // console.log(evt.delta.y, evt.limit.y, window.innerHeight)

            if (evt.delta.y > 60 && evt.direction === 'down') {
              this.hideNav = true
            }
            /*
            console.log(evt.delta.y, 'DELTA Y')
            console.log(evt, 'EVT')
            console.log(evt.limit.y, 'LIMIT Y')
            */
            if (evt.delta.y < 60 && evt.direction === 'up') {
              this.hideNav = false
            }
            /*
            const limit = evt.limit.y - window.innerHeight - 20

            if (evt.delta.y > limit && !this.stopResizeFix) {
              this.resize()
              this.stopResizeFix = true
            }
            */
/*
            console.log('handleScroll', evt)

            const currentSection = evt.currentElements
            console.log(currentSection, 'CURRENT SECTION')
            const objTarget = Object.assign({}, currentSection)
            console.log(objTarget, 'CURRENT SECTION')
*/
            // this.$emitter.emit('scroll', evt)
        },
        resize () {
            console.log('app resize()')

            fixVh()

            if (this.scroll) {
                this.scroll.update() // Updates all element positions
                this.scroll.start() // Restarts the scroll events
            }

            if (this.$refs.cursorfilter) {
              this.$store.commit('resize')
              this.$refs.cursorfilter.cursorFilterReload()
            }
        },
        // transitions ------------------------------------
        enterTransition (el, next) {
          // !! FOR ANIMATIONS
            this.anims_els = [...document.querySelectorAll('[data-anim-scroll]')]
            this.anims_els.forEach((el) => {
                const anim = el.dataset.animScroll

                if (animations.has(anim)) {
                    animations.get(anim + '-initial')(el)
                }
            })

            // Commented for bug
            // if (this.scroll) this.scroll.scrollTo(0, { duration: 0, disableLerp: true })

            transitions.get('fallback')(el, 'in').then(() => {
                this.$store.commit('set_is_in_transition', false)
                next()
            })
            /* HERE */
        },
        leaveTransition (el, next) {
            console.log('app leaveTransition()')

            // Exchanged with enter transition for bug
            if (this.scroll) {
                this.scroll.scrollTo(0, { duration: 900 })
                // this.stopResizeFix = false
            }

            transitions.get('fallback')(el, 'out').then(() => {
                next()
            })

            /*
            try {
                await this.getPage(done)
            } catch (err) {
                done()
                return this.$router.push({ name: 'error_404' })
            }

            done()
            */
        },
      stagger () {
        console.log('stagger')
      }
    }
}
</script>

<style lang="scss">
@import "@/assets/styles/index.scss";
</style>
