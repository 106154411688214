<template>
  <section class="page-heading">
    <div
    :class="[
        'page-heading__bg',
        {
            'page-heading__bg--svg': svgbg,
            'page-heading__col2': col2,
            'content-big': contentBig
        }
    ]">
      <div v-if="!col2">
        <bxs-layout>
          <bxs-row>
            <bxs-col
            cols="12"
            ds="12">
              <div class="page-heading__titlegroup">
                <h1 ref="title">{{ title }}</h1>
                <h2 ref="subtitle">
                  {{ subtitle }}
                  <div ref="mark" class="mark"></div>
                </h2>
              </div>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </div>
      <div v-if="col2">
        <bxs-layout>
          <bxs-row>
            <bxs-col
            cols="12"
            ds="7">
              <div class="page-heading__titlegroup page-heading__titlegroup--col2">
                <h1 ref="title">{{ title }}</h1>
                <h2 ref="subtitle">
                  {{ subtitle }}
                  <div ref="mark" class="mark"></div>
                </h2>
              </div>
            </bxs-col>
            <bxs-col
            cols="12"
            ds="5">
              <div ref="figureWrapper" class="figure-wrapper">
                <bxs-figure
                  :src="src"
                >
                </bxs-figure>
              </div>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </div>
    </div>
  </section>

</template>

<script>
import { gsap } from 'gsap'
export default {
    name: 'plcd-page-heading',
    props: {
        title: {
            type: String,
            required: true,
            default: 'Titolo della pagina'
        },
        subtitle: {
            type: String,
            required: false,
            default: ''
        },
        svgbg: {
          type: Boolean,
          required: false,
          default: true
        },
        col2: {
          type: Boolean,
          required: false,
          default: false
        },
        contentBig: {
          type: String,
          required: false
        },
        src: {
            type: String,
            required: false,
            default: ''
        }

    },

    mounted() {
      this.startAnimation()
    },

    watch: {
      isPageTransitionEnded(value) {
        if (value === false) {
          this.startAnimation()
        }
      }
    },

    methods: {
      startAnimation() {
        const titleMotion = gsap.timeline({
          onComplete: () => {
            console.log(this.$refs.subtitle)
            const figureShow = gsap.timeline()
            figureShow
            .fromTo(this.$refs.figureWrapper, {
              opacity: 0,
              y: 10
            }, {
              opacity: 1,
              y: 0,
              ease: 'power3.easeOut'
            })
          }
        })
        titleMotion.delay(0.3)

        titleMotion
          .fromTo(this.$refs.title, {
              opacity: '0',
              y: 50
          }, {
              opacity: '1',
              y: 0,
              duration: 0.3,
              ease: 'power3.easeOut'
          })
          .fromTo(this.$refs.subtitle, {
              opacity: '0'
          }, {
              opacity: '1',
              duration: 0.5,
              ease: 'power3.easeOut'
          })
          .fromTo(this.$refs.mark, {
              clipPath: 'inset(0 0 0 100%)',
              webkitClipPath: 'inset(0 0 0 100%)'
          }, {
              clipPath: 'inset(0 0 0 0%)',
              webkitClipPath: 'inset(0 0 0 0%)',
              duration: 0.7,
              ease: 'power4'
          }, '-=0.2')
      }
    }
}

</script>
<style scoped lang="scss">
@import '@/assets/styles/mixins.scss';

.heading-boxed{
 width: 80%;
}

.page-heading{
  padding: 0 !important;

  .content-big{
    padding-bottom: 280px;
  }

  &__bg{
    padding-top: var(--distance-vertical-d);
    // padding-bottom: var(--distance-vertical-d);
    padding-bottom: 80px;
    background: url('@/assets/svg/gammatom-endless-bg.svg');
    background-size: 10%;

    @include breakpoint(app_mobile) {
      padding-top: 50px !important;
      padding-bottom: 50px !important;
    }
  }

  &__col2{
    padding-bottom: 30px;
  }

  &__titlegroup{
    padding-top: 80px;
    // padding-bottom: var(--distance-vertical-d);

    @include breakpoint(app_mobile) {
      padding-bottom: 50px !important;
    }

    h1{
      font-size: 68px;
      line-height: 68px;
      letter-spacing: 0.005em;
      opacity: 0;

      @include breakpoint(mobile) {
        font-size: 48px;
        line-height: 70px;
      }
    }

    h2{
      opacity: 0;
      font-size: 28px;
      // padding-top: var(--distance-vertical-d-subtitle);
      display: inline-block;
      letter-spacing: .02em;
      margin-bottom: 0;

      @include breakpoint(desktop) {
        font-size: 38px;
      }

      @include breakpoint(app_mobile) {
        font-size: 30px;
      }
    }

    &--col2{

      h1{
        font-size: 40px;
        line-height: 46px;
        letter-spacing: 0.005em;
        opacity: 0;
      }

      h2{
        opacity: 0;
        font-size: 24px;
        padding-top: var(--distance-vertical-d-subtitle);
        display: inline-block;
        padding: 0;
      }

    }
    .mark{
      background: var(--color-primary);
      width: 100%;
      height: 5px;
    }
  }
  .figure-wrapper{
      border: 1px solid var(--color-lightgrey);
      opacity: 0;
    }

    &__col2{
      .figure-wrapper{
        margin: 50px 0 0 50px;
      }
    }
}
</style>
