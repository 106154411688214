<template>
  <div class="page">
      <!-- HEADING 1 IMAGE BIG -->

      <plcd-page-heading
        title="Whistleblowing"
        :subtitle="page.plcd_page_subtitle"
        :svgbg="true"
      >
      </plcd-page-heading>

      <section class="page-content" id="contentSection">
        <bxs-layout>
          <bxs-row>
            <bxs-col
            cols="12"
            ds="12"
            >
            <div class="page-content__text">
              <bxs-row>
                <bxs-col
                cols="12"
                ds="6"
                >
                  <div class="form-wrapper contact-page-form" v-html="page && page.plcd_page_content">
                  </div>
                  <!--
                  <div class="form-wrapper contact-page-form">
                    <h2 class="contact-title text-center">{{ $translate('Contattaci', activeLang) }}</h2>
                    <form action="" method="">
                      <div class="form-row"><input type="text" placeholder="Tipologia segnalante" value=""></div>
                      <div class="form-row"><input type="email" placeholder="Tipologia illecito" value=""></div>
                      <div class="form-row"><input type="text" placeholder="Autori illecito" value=""></div>
                      <div class="form-row"><input type="text" placeholder="Persone informate" value=""></div>
                      <div class="form-row"><input type="text" placeholder="Data Presunta" value=""></div>
                      <div class="form-row form-row--last">
                        <textarea placeholder="Descrizione dettagliata dei fatti (max 5000 caratteri)">
                        </textarea>
                      </div>
                      <div class="privacy-box-container">
                        <label>
                          <input type="checkbox"><small> Per presa visione dell'informativa privacy ai sensi del RE 2016/679</small>
                        </label>
                      </div>
                      <i>Le richieste inviate tramite il presente form saranno gestite entro massimo 48 ore lavorative dalla ricezione.</i>
                      <div class="buttons-set buttons-set--footer">
                        <bxs-btn
                        append-icon="arrow-right"
                        text>Invia Richiesta</bxs-btn>
                      </div>
                    </form>
                  </div>
                -->
                </bxs-col>
                <bxs-col
                cols="12"
                ds="6"
                >
                <bxs-figure :src="page.img" />
                </bxs-col>
              </bxs-row>
            </div>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>
      <!--
        <bxs-col
        cols="12"
        ds="6"
        class="bxs-col-no-padding bxs-bg-col"
        >
      -->

      <plcd-driving-banner
        :title="page.plcd_driving_banner_page_title"
        :to="page.plcd_driving_banner_link"
        :src="page.plcd_driving_banner_image"
      >
      </plcd-driving-banner>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'whistleblowing',
    computed: {
      ...mapState({
        language: state => state.language,
        pages(state) {
          const languagePages = {
              it: state.pages,
              en: state.pagesEn
          }
          return languagePages[this.language] || state.pages
          },
          page() {
            const objArray = Object.values(this.pages)
            return objArray.filter(pages => pages.template === 'template-whistleblowing.php')[0]
          },
          activeLang() {
            return this.$store.state.language
          }
      })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins.scss';
.contact-title{
  letter-spacing: .006em;

  &.text-center{
    margin-bottom: 50px;
  }
}
.contact-content{
  letter-spacing: 0.006em;
}
.contact-second-section{
  margin-top: 100px;
}
.contact-page-form{
  max-width: 82%;

  i{
    font-size: 12px;
    margin: 0 0 20px;
    display: inline-block;
  }
}
</style>
